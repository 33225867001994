import {Typography} from "@mui/material";
import {Strings} from "../../../Resources/Strings";
import Box from "@mui/material/Box";
import {margin} from "../../../Resources/styles";

export const GenericNotFoundedComponent = () => {
    return <>
        <Box m={margin}>
            <Typography variant='h6'>{Strings.NotFounded404}</Typography>
        </Box>
    </>
}