export class CommercialPictureConfiguration {
    public imageSize: number = 1024
    public useBlur: boolean = true
    public blurRadius: number = 3
    public plantSaturation: number = 1.3
    public backgroundSaturation: number = 0
    public contrast: number = 1.1
    public frameFactor: number = 0.2
    public backgroundUrl: string = 'https://storage.googleapis.com/epipremnum-production/background/6.png'


    public getConfiguration(): string {
        let query = `&imageSize=${this.imageSize}&useBlur=${this.useBlur}`
        query += `&blurRadius=${this.blurRadius}&frameFactor=${this.frameFactor}`
        query += `&plantSaturation=${this.plantSaturation}&backgroundSaturation=${this.backgroundSaturation}`
        query += `&contrast=${this.contrast}&backgroundUrl=${this.backgroundUrl}`
        return query
    }
}