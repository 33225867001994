import React, {useEffect, useState} from "react";
import {plantSheetService} from "../../api/plantSheetService";
import {Avatar, Box, FormControlLabel, IconButton, ListItemAvatar, Slider, Switch, Typography} from "@mui/material";
import {Strings} from "../../Resources/Strings";
import {IPlantSheetModel} from "../../api/IPlantSheetModel";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import DeleteIcon from "@mui/icons-material/Delete";
import ViewInArIcon from "@mui/icons-material/ViewInAr";
import ListItemText from "@mui/material/ListItemText";
import {apiUtils} from "../../api/ApiConfig";
import {store} from "../../redux/store";
import {setInProgressOffThunk, setInProgressOnThunk} from "../../redux/progress";
import DownloadIcon from '@mui/icons-material/Download';
import {useConfirm} from "material-ui-confirm";

export const PlantSheetCollectionComponent = () => {

    const [plantSheets, setPlantSheets] =
        useState<IPlantSheetModel[]>()

    const confirm = useConfirm()

    useEffect(() => {
        loadData()
    }, [])

    const loadData = async () => {
        const plantSheets = await plantSheetService.readAsync()
        setPlantSheets(plantSheets)
    }

    const onPrintChanged = async (plantSheet: IPlantSheetModel) => {
        await store.dispatch(setInProgressOnThunk())
        await plantSheetService.updateAsync({
            id: plantSheet.id,
            setIsPrinted: !plantSheet.isPrinted
        })
        await loadData()
        await store.dispatch(setInProgressOffThunk())
    }

    const downloadSheetCallback = async (id: string) => {
        try {
            await confirm()
            await store.dispatch(setInProgressOnThunk())
            await plantSheetService.downloadSheet(id)
            await store.dispatch(setInProgressOffThunk())
        }
        catch {

        }
    }

    return <>
        <Typography variant='h5'>
            {Strings.PlantSheet}
        </Typography>

        <List>
            {plantSheets && plantSheets.map(plantSheet =>
                <ListItem key={plantSheet.id}
                          secondaryAction={
                              <Box>
                                  <FormControlLabel control={<Switch
                                      color='primary'
                                      checked={plantSheet.isPrinted}
                                      onChange={async () => {
                                          await onPrintChanged(plantSheet)
                                      }}/>} label={Strings.Printed}/>

                                  <IconButton onClick={async event => {
                                      await downloadSheetCallback(plantSheet.id)
                                  }} color='primary'>
                                      <DownloadIcon/>
                                  </IconButton>
                              </Box>

                          }>
                    <ListItemAvatar>
                        <Avatar variant='circular'>
                            <ViewInArIcon/>
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={`${plantSheet.id}`}
                                  secondary={`${apiUtils.mongoIdToDate(plantSheet.id).toDateString()}`}/>
                </ListItem>)}
        </List>
    </>
}