import React, {FC, useEffect, useRef} from "react";
import {fabric} from "fabric";
import {SpeedDial, SpeedDialAction} from "@mui/material";
import AspectRatioIcon from '@mui/icons-material/AspectRatio';
import AddIcon from '@mui/icons-material/Add';
import {IRoiInImageData} from "./IRoiInImageData";
import {Strings} from "../../../Resources/Strings";
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {resizePictureInFabricCanvas, setRoiMaximumSize} from "../../../logic/fabricUtils";
import Box from "@mui/material/Box";
import {IDataURLOptions} from "fabric/fabric-impl";

interface RoiInImageDataTemplateComponentProps {
    imageUrl: string,
    onRoiImage: (data: IRoiInImageData) => void
    onCancel: () => void
}

export const RoiInImageDataTemplateComponent: FC<RoiInImageDataTemplateComponentProps> = (props) => {

    const canvasRef = useRef<HTMLCanvasElement>(null)
    const fabricCanvasRef = useRef<fabric.Canvas>()

    const scaleXRef = useRef<number>(1)
    const scaleYRef = useRef<number>(1)

    const pictureRef = useRef<fabric.Image>()
    const roiRectRef = useRef<fabric.Rect>();

    useEffect(() => {
        // Set initial positions
        window.addEventListener('resize', onWindowResizeCallback);
    }, [])

    useEffect(() => {
        fabricCanvasRef.current = new fabric.Canvas(canvasRef.current, {
            backgroundColor: 'rgba(255,255,255,0)',
            selectionLineWidth: 2,
            uniformScaling: true,
        });

        fabric.Image.fromURL(props.imageUrl,
            loadPictureCallback, {
                centeredScaling: true,
                selectable: false,
                moveCursor: 'default',
                hoverCursor: 'default',
                opacity: 1
            })
    }, [])

    const loadPictureCallback = (image: fabric.Image) => {
        const canvasFabric = fabricCanvasRef.current!
        canvasFabric?.add(image)
        pictureRef.current = image
        onWindowResizeCallback()

        let picture = pictureRef.current!

        let pictureWidth = picture.getScaledWidth()
        let pictureHeight = picture.getScaledHeight()

        let roiLength = pictureWidth
        if (pictureHeight < roiLength)
            roiLength = pictureHeight

        // Add ROI
        let roiRect = new fabric.Rect({
            left: picture.left! + pictureWidth / 2 - roiLength / 2,
            top: picture.top! + pictureHeight / 2 - roiLength / 2,
            width: roiLength,
            height: roiLength,
            fill: 'rgba(0,127,0,0.05)',
            lockSkewingX: true,
            lockSkewingY: true,
            lockScalingFlip: true,
            lockRotation: true,
            lockUniScaling: true,
            centeredScaling: false,
            stroke: 'rgb(46,52,52)',
            cornerColor: 'rgb(46,52,52)',
            cornerStrokeColor: 'rgb(46,52,52)',
            borderColor: 'rgb(46,52,52)',
            transparentCorners: false,
            cornerStyle: 'circle',
            strokeWidth: 1,
            hasRotatingPoint: false,
        })


        scaleXRef.current = 1
        scaleYRef.current = 1
        fabricCanvasRef.current?.on("mouse:move", () => {
            if (!roiRectRef.current)
                return

            const currentCanvas = fabricCanvasRef.current!
            if (scaleXRef.current !== roiRectRef.current.scaleX)
                roiRectRef.current.scaleY = roiRectRef.current.scaleX
            if (scaleYRef.current !== roiRectRef.current.scaleY)
                roiRectRef.current.scaleX = roiRectRef.current.scaleY


            scaleXRef.current = roiRectRef.current!.scaleX!
            scaleYRef.current = roiRectRef.current!.scaleY!

            const rect = roiRectRef.current!
            if (rect.left! < 0) {
                rect.left = 0
            }
            if (rect.top! < 0) {
                rect.top = 0
            }

            const right = rect.left! + rect.getScaledWidth()!
            if (right > currentCanvas.width!) {
                rect.left = rect.left! - (right - currentCanvas.width!)
            }
            const bottom = rect.top! + rect.getScaledHeight()!
            if (bottom > currentCanvas.height!) {
                rect.top = rect.top! - (bottom - currentCanvas.height!)
            }
        })


        canvasFabric.add(roiRect)
        roiRectRef.current = roiRect
        canvasFabric.renderAll()
    }

    const roiImageCallback = () => {
        let fabricCanvas = fabricCanvasRef.current!
        let picture = pictureRef.current!
        let roiRect = roiRectRef.current!

        let multiplier = picture.width! / fabricCanvas.width!

        let width = roiRect.getScaledWidth()
        let height = roiRect.getScaledHeight()

        fabricCanvas.remove(roiRect)
        fabricCanvas.renderAll()


        let options:IDataURLOptions = {
            left: roiRect.left,
            top: roiRect.top,
            width: width,
            height: height,
            format: 'png',
            multiplier: multiplier,
        }
        let url = fabricCanvas.toDataURL(options)
        props.onRoiImage({
            roiImageUrl: url
        })
    }

    const onWindowResizeCallback = () => {
        resizePictureInFabricCanvas(fabricCanvasRef, pictureRef, canvasRef)
    }

    return <Box sx={{"border": 1}}>
        <SpeedDial ariaLabel="Options"
                   sx={{position: 'absolute', bottom: 16, right: 16}}
                   icon={<AddIcon/>}>
            <SpeedDialAction
                key='confirm'
                onClick={roiImageCallback}
                icon={<CheckCircleIcon color='success'/>}
                tooltipTitle={Strings.ConfirmEdition}
            />

            <SpeedDialAction
                key='cancel'
                onClick={() => {
                    props.onCancel()
                }}
                icon={<CancelIcon color='error'/>}
                tooltipTitle={Strings.TakePictureAgain}
            />

            <SpeedDialAction
                key='resize'
                onClick={() => {
                    if (!pictureRef.current || !roiRectRef.current || !fabricCanvasRef.current)
                        return

                    const picture = pictureRef.current!
                    const roiRect = roiRectRef.current!
                    const fabricCanvas = fabricCanvasRef.current!

                    setRoiMaximumSize(picture, roiRect, fabricCanvas)
                }}
                icon={<AspectRatioIcon/>}
                tooltipTitle={Strings.MaxSize}
            />
        </SpeedDial>

        <canvas ref={canvasRef} className='qr-lines'/>
    </Box>
}