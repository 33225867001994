import {Button, FormGroup, Grid, Stack, TextField, Typography} from "@mui/material";
import React from "react";
import {spacing} from "../../../Resources/styles";

export const DrillHoleCreateCampaignComponent = () => {
    return <Grid container spacing={spacing}>
        <Grid item xs={12}>
            <Typography variant='h5'>
                Crear Campaña
            </Typography>
        </Grid>
        <Grid item xs={12}>
            <Grid container spacing={spacing} alignItems='center'>
                <Grid item xs={8}>
                    <TextField fullWidth label="Nombre" variant="outlined"/>
                </Grid>

                <Grid item xs={4}>
                    <Button fullWidth variant="contained" component="label">
                        Cargar datos
                        <input type="file" hidden/>
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    </Grid>
}