import {ViewerDataTemplateComponent} from "../../dataTemplate/viewer/ViewerDataTemplateComponent";
import React, {useEffect, useState} from "react";
import {Base3dViewerManager} from "../../dataTemplate/viewer/Base3dViewnerManager";
import {
    AxesHelper,
    BoxGeometry,
    Mesh,
    MeshStandardMaterial,
    Quaternion, TextureLoader
} from "three";
// @ts-ignore
import * as hdf5 from 'jsfive';
import JSZip from "jszip";
import {StopWatch} from 'stopwatch-node';
import {meshService} from "../../../api/demo/meshService";

const textureUrl = "https://storage.googleapis.com/epipremnum-development/template/Imagen_test.jpg"

export const TestViewerComponent = () => {

    const [manager] = useState(() => new Base3dViewerManager())


    const [currentRotation, setCurrentRotation]
        = useState<Quaternion>(new Quaternion())


    const initCallback = async () => {
        const textureLoader = new TextureLoader();

        const texture = textureLoader.load(
            textureUrl
        );
        const url = 'https://storage.googleapis.com/epipremnum-development/template/Output%20H5.zip'
        // const url = 'https://storage.googleapis.com/epipremnum-development/template/Output%20Optimal.zip'


        const mesh = await meshService.getMesh(url)
        manager.scene.add(mesh)


        manager.fitCamera()


    }

    return <>
        <ViewerDataTemplateComponent manager={manager} viewerInitCallback={initCallback}/>
    </>
}