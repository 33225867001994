import {useSelector} from "react-redux";
import {RootState, store} from "../../../redux/store";
import {useEffect, useState} from "react";
import {Strings} from "../../../Resources/Strings";
import moment, {Moment} from "moment/moment";
import {useNavigate, useParams} from "react-router-dom";
import {setSelectEpipremnumThunk} from "../../../redux/epipremnum";
import {epipremnumRawPictureService} from "../../../api/epipremnumRawPictureService";
import {RawPictureDataTemplateComponent} from "../../dataTemplate/rawPicture/rawPictureDataTemplateComponent";
import Resizer from "react-image-file-resizer";
import {epipremnumService} from "../../../api/epipremnumService";
import {setInProgressOffThunk} from "../../../redux/progress";
import {useConfirm} from "material-ui-confirm";
import {useSnackbar} from "notistack";
import { thumbnailPixels } from "../../../Resources/styles";


export const EpipremnumRawPictureCreateComponent = () => {
    const {currentEpipremnumSelected} = useSelector((state: RootState) => state.epipremnum)
    const confirm = useConfirm()
    const snackbar = useSnackbar()
    const {epipremnumId} = useParams()
    const [label, setLabel] = useState<string>("")

    const navigate = useNavigate()

    useEffect(() => {
        if (!epipremnumId) return
        store.dispatch(setSelectEpipremnumThunk(epipremnumId))
        setLabel(`${Strings.EpipremnumRawPicture} ${epipremnumId}`)
    }, [epipremnumId])

    const onUndoPictureCallback = async (id: string) => {
        await epipremnumRawPictureService.deleteAsync(id)
        navigate(`/epipremnumRawPicture/create/${epipremnumId}`)
    }


    const onPictureCreateCallback = async (roiPicture: string, imageWidth: number, imageHeight: number,
                                           description: string, pictureDate: moment.Moment) => {
        let model = await epipremnumRawPictureService.createAsync(epipremnumId!,
            imageWidth, imageHeight, pictureDate, roiPicture, description)
        navigate(`/epipremnumRawPicture/${model.id}`)

        // Set picture callback

        try {
            await confirm({title: Strings.AssignAsThumbnail})
            fetch(roiPicture).then(value => {
                value.blob().then(blob => {
                    Resizer.imageFileResizer(
                        blob,
                        thumbnailPixels,
                        thumbnailPixels,
                        'png',
                        100,
                        0,
                        async value => {
                            let item = await epipremnumService.setThumbnail(epipremnumId!, value as string)
                            snackbar.enqueueSnackbar(`${Strings.UpdatedThumbnail} ${item.thumbnail}`)
                        }, // Is the callBack function of the resized new image URI.
                        "base64"// Is the output type of the resized new image.
                    );
                })
            })

        } catch {

        }
        return model.id;
    }

    return <>
        {currentEpipremnumSelected &&
            <RawPictureDataTemplateComponent onUndoPicture={onUndoPictureCallback}
                                             onPictureCreate={onPictureCreateCallback} label={label}/>
        }
    </>
}