import {useConfirm} from "material-ui-confirm";
import {useNavigate, useParams} from "react-router-dom";
import React, {useEffect, useRef, useState} from "react";
import {IPlantModel} from "../../api/IPlantModel";
import {StorageComponentViewerManager} from "../../logic/Viewer3D/StorageComponentViewerManager";
import {IPlantShelfCubicleModel} from "../../api/IPlantShelfCubicleModel";
import {plantService} from "../../api/plantService";
import {plantShelfService} from "../../api/plantShelfService";
import {store} from "../../redux/store";
import {setInProgressOffThunk, setInProgressOnThunk} from "../../redux/progress";
import {plantShelfCubicleService} from "../../api/plantShelfCubicleService";
import {
    Box,
    Grid,
    Typography
} from "@mui/material";
import {spacing} from "../../Resources/styles";
import {Strings} from "../../Resources/Strings";
import {ViewerDataTemplateComponent} from "../dataTemplate/viewer/ViewerDataTemplateComponent";

export const PlantStorageViewerComponent = () => {

    const confirm = useConfirm()
    const {plantId} = useParams()
    const navigate = useNavigate()


    const [plant, setPlant] = useState<IPlantModel>()
    const [manager] = useState<StorageComponentViewerManager>(new StorageComponentViewerManager())
    const [hoverCubicle, setHoverCubicle] =
        useState<IPlantShelfCubicleModel>()

    const initViewerCallback = async () => {
        await store.dispatch(setInProgressOnThunk())

        manager.setProps({
            disableSelectable: true,
            onSelectedCubicle: cubicle => {

            },
            onUnselectedCubicle: cubicle => {

            },
            onMouseOverCubicle: cubicle => {
                setHoverCubicle(cubicle)
            },
            multipleSelectable: true
        })

        const plant = await plantService.readOneAsync(plantId!)
        setPlant(plant)
        if (plant!.storedInCubicles.length == 0) {
            await store.dispatch(setInProgressOffThunk())
            return
        }
        const cubicleId = plant!.storedInCubicles[0]._id
        const cubicleModel = await plantShelfCubicleService.readOneAsync(cubicleId)
        const shelfModel = await plantShelfService.readAsync(cubicleModel.plantShelf._id)
        const plantShelfCubicleModels = await plantShelfCubicleService
            .readAsync({shelfIds: [shelfModel.id]})

        await manager.setItems(shelfModel, plantShelfCubicleModels, [plant])
        await store.dispatch(setInProgressOffThunk())
        manager.fitCamera()
    }


    return <Grid container spacing={spacing}>
        {plant && <Grid item xs={12}>
            <Typography variant='h5'>
                {Strings.Storage} {plant.name}
            </Typography>
        </Grid>}

        <Grid item xs={12}>
            <ViewerDataTemplateComponent manager={manager} viewerInitCallback={initViewerCallback}/>
        </Grid>
    </Grid>
}