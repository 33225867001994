import {DrillHoleFilterAbstract} from "./DrillHoleFilterAbstract";
import {IDrillHoleCollarClientModel} from "../IDrillHoleCollarClientModel";

export class DrillHoleFilterAllVisible extends DrillHoleFilterAbstract {
    private _visible: boolean;

    constructor(visible: boolean = true) {
        super();
        this._visible = visible;
    }

    filter(collar: IDrillHoleCollarClientModel): boolean[] {
        return collar.assay.map(value => {
            return this._visible
        });
    }
}