import {VideoPlantQrLocation} from "./videoPlantQrLocation";
import jsQR, {QRCode} from "jsqr";
import {Point} from "jsqr/dist/locator";

export class QrAnalysisEngine {
    public currentQrLocation?: VideoPlantQrLocation
    public currentQrCode?: QRCode

    public lastQrLocation?: VideoPlantQrLocation
    public lastQRCode?: QRCode

    searchQr(imageData: ImageData, factor: number = 1): boolean {
        let result = jsQR(imageData.data, imageData.width, imageData.height)
        if (!result) {
            this.currentQrCode = undefined
            this.currentQrLocation = undefined
            return false;
        }
        // Storage data
        let location = result.location
        let x0 = location.topLeftCorner.x / factor
        let y0 = location.topLeftCorner.y / factor
        let x1 = location.bottomRightCorner.x / factor
        let y1 = location.bottomRightCorner.y / factor

        const leftCorner = {x: x0, y: y0}
        const rightCorner = {x: x1, y: y1}
        let bottomRightAlignment: Point | null = null;


        if (location.bottomRightAlignmentPattern) {
            let toX = location.bottomRightAlignmentPattern.x / factor
            let toY = location.bottomRightAlignmentPattern.y / factor
            bottomRightAlignment = {x: toX, y: toY}
        }
        this.currentQrLocation = new VideoPlantQrLocation(leftCorner, rightCorner, bottomRightAlignment)
        this.currentQrCode = result
        this.lastQrLocation = new VideoPlantQrLocation(leftCorner, rightCorner, bottomRightAlignment)
        this.lastQRCode = result
        return true
    }
}