import {epipremnumService} from "../api/epipremnumService";
import {ICheckQrCode} from "./ICheckQrCode";

export const checkEpipremnumQrCode = async (qrText: string) => {
    try {
        let splits = qrText.split('/')
        let id = splits[splits.length - 1]

        let epipremnumModel = await epipremnumService.readAsync(id)
        return {
            id: epipremnumModel.id,
            isValid: true
        } as ICheckQrCode

    } catch (e) {
        return {
            id: '',
            isValid: false
        } as ICheckQrCode
    }
}

