import {useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {
    Button,
    Fab,
    Grid,
    ImageList,
    ImageListItem,
    TextField
} from "@mui/material";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import * as muiImage from "mui-image";
import {spacing} from "../../Resources/styles";
import {RoiInImageDataTemplateComponent} from "../dataTemplate/roiInImageEditor/roiInImageDataTemplateComponent";
import {Strings} from "../../Resources/Strings";
import {plantImageService} from "../../api/planImageService";
import {useConfirm} from "material-ui-confirm";
import {setInProgressOffThunk, setInProgressOnThunk} from "../../redux/progress";
import {store} from "../../redux/store";

export const PlantCreateImageComponent = () => {

    const {plantId} = useParams()
    const [image, setImage] = useState<string>()
    const [croppedImage, setCroppedImage] = useState<string>()
    const [description, setDescription] = useState<string>()

    const confirm = useConfirm()


    useEffect(() => {
        if (plantId === undefined)
            return;
    }, [plantId])


    const uploadPicture = async () => {
        await confirm().then(async () => {
            await store.dispatch(setInProgressOnThunk())
            const plantImageModel = await plantImageService.createAsync
            (plantId!, croppedImage!, description)
        }).catch(() => {

        }).finally(async () => {
            await store.dispatch(setInProgressOffThunk())
            setImage(undefined)
            setCroppedImage(undefined)
        })
    }

    const inputImageCameraPictureCallback = (event: any) => {
        let htmlInputElement: HTMLInputElement = event.target
        if (htmlInputElement.files == null) return;

        let reader = new FileReader();
        reader.readAsDataURL(htmlInputElement.files[0]);

        reader.onload = function () {
            setImage(reader.result as string)
        };
    }

    if (image && !croppedImage)
        return <RoiInImageDataTemplateComponent imageUrl={image} onRoiImage={data => {
            setCroppedImage(data.roiImageUrl)
        }} onCancel={() => {
            setImage(undefined)
        }}/>

    if (croppedImage) {
        return <Grid container spacing={spacing}>
            <Grid item xs={12}>
                <ImageList>
                    <ImageListItem>
                        <muiImage.Image height='300px' alt='initial'
                                        src={croppedImage}
                                        fit='contain'/>
                    </ImageListItem>
                </ImageList>
            </Grid>

            <Grid item xs={4}>
                <Button variant='contained' onClick={() => {
                    setCroppedImage(undefined)
                }} fullWidth>
                    {Strings.CropAgain}
                </Button>

            </Grid>

            <Grid item xs={4}>
                <Button variant='contained' onClick={() => {
                    setCroppedImage(undefined)
                    setImage(undefined)
                }} fullWidth>
                    {Strings.TakePictureAgain}
                </Button>
            </Grid>

            <Grid item xs={4}>
                <Button variant='contained' onClick={uploadPicture} fullWidth>
                    {Strings.Upload}
                </Button>
            </Grid>

            <Grid item xs={12}>
                <TextField
                    fullWidth
                    label={Strings.Description}
                    value={description} onChange={event => {
                    setDescription(event.target.value)
                }}
                    multiline
                    minRows={4}
                    maxRows={10}
                    variant="outlined"
                />
            </Grid>
        </Grid>
    }

    return <Grid container spacing={spacing}>
        <Grid item xs={12}>
            <Fab variant='circular'
                 component="label" size='medium' color='primary'>
                <input
                    type="file"
                    accept="image/*"
                    onChange={inputImageCameraPictureCallback}
                    hidden
                    capture="environment"
                />
                <CameraAltIcon/>
            </Fab>
        </Grid>
    </Grid>
}