import {IDrillHoleCollarClientModel} from "./IDrillHoleCollarClientModel";
import {Line} from "three";
import {DrillHoleLineContainer} from "./DrillHoleLineContainer";
import {DrillHoleFilterByCut} from "./filter/DrillHoleFilterByCut";
import {DrillHoleFilterAllVisible} from "./filter/DrillHoleFilterAllVisible";
import moment from "moment";
import {DrillHoleFilterAbstract} from "./filter/DrillHoleFilterAbstract";


export interface IDrillHoleAssayRange {
    minCut: number
    maxCut: number

    fromDate: Date,
    toDate: Date,
    minProgressPercentage: number,
    maxProgressPercentage: number,
}


export class DrillHoleAssayViewerContainer {
    private readonly _collars: IDrillHoleCollarClientModel[]
    private readonly _drillHoleContainers: DrillHoleLineContainer[] = []


    constructor(collars: IDrillHoleCollarClientModel[]) {
        this._collars = collars
        for (const collar of collars) {
            this._drillHoleContainers.push(new DrillHoleLineContainer(collar))
        }
    }

    getLines(): Line[] {
        const lines: Line[] = []
        for (let drillHoleLineContainer of this._drillHoleContainers) {
            lines.push(drillHoleLineContainer.getLine())
        }
        return lines
    }

    public filterByCut(min: number, max: number, includeInvalid: boolean) {
        for (let drillHoleContainer of this._drillHoleContainers) {
            const filterByCut = new DrillHoleFilterByCut(
                min, max, includeInvalid)
            drillHoleContainer.computeIndices(filterByCut.filter(drillHoleContainer.getCollar()))
        }
    }

    public filter(filters: DrillHoleFilterAbstract[]) {
        for (let drillHoleContainer of this._drillHoleContainers) {
            const collar = drillHoleContainer.getCollar()
            const numberOfIndices = collar.assay.length
            const statusArray = filters.map(value => value.filter(collar))

            const finalStatus: boolean[] = []
            for (let position = 0; position < numberOfIndices; position++) {
                const status = statusArray.map(value => value[position]).every(value => value)
                finalStatus.push(status)
            }
            drillHoleContainer.computeIndices(finalStatus)
        }
    }


    public reset() {
        for (let drillHoleContainer of this._drillHoleContainers) {
            const allVisible = new DrillHoleFilterAllVisible(true)
            drillHoleContainer.computeIndices(allVisible.filter(drillHoleContainer.getCollar()))
        }
    }

    public getRange(): IDrillHoleAssayRange {

        const cuts: number[] = []
        const fromDates: Date[] = []
        const toDates: Date[] = []
        const progress: number[] = []

        this._drillHoleContainers.forEach(drillHoleLineContainer => {

            const collar = drillHoleLineContainer.getCollar()
            if (collar.startDate)
                fromDates.push(moment(collar.startDate).toDate())
            if (collar.endDate)
                toDates.push(moment(collar.endDate).toDate())

            if (collar.compliancePercentage)
                progress.push(collar.compliancePercentage)

            collar.assay.forEach(assayClient => {
                if (assayClient.cut != -99)
                    cuts.push(assayClient.cut)
            })
        })
        return {
            minCut: Math.min(...cuts),
            maxCut: Math.max(...cuts),
            fromDate: new Date(Math.min(...fromDates.map(value => value.getTime()))),
            toDate: new Date(Math.max(...toDates.map(value => value.getTime()))),
            minProgressPercentage: Math.min(...progress),
            maxProgressPercentage: Math.max(...progress)
        }
    }
}

