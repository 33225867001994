import {combineReducers} from "redux"
import {persistStore, persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {epipremnum} from "./epipremnum";
import {configureStore} from "@reduxjs/toolkit";
import {progress} from "./progress";


const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['epipremnum']
}

let reducers = combineReducers({
    epipremnum: epipremnum.reducer,
    progress: progress.reducer,
})

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({serializableCheck: false}),
})
export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
