import React, {FC, useEffect, useState} from "react";
import moment, {Moment} from "moment/moment";
import {DrillHoleFilterAllVisible} from "../../../models/demo/filter/DrillHoleFilterAllVisible";
import {DrillHoleFilterByDate} from "../../../models/demo/filter/DrillHoleFilterByDate";
import {Checkbox, FormControlLabel, Slider, Stack, Switch, TextField, Typography} from "@mui/material";
import {spacing} from "../../../Resources/styles";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import {DrillHoleAssayViewerContainer} from "../../../models/demo/DrillHoleAssayViewerContainer";
import {DrillHoleFilterAbstract} from "../../../models/demo/filter/DrillHoleFilterAbstract";
import {DrillHoleFilterByCut} from "../../../models/demo/filter/DrillHoleFilterByCut";
import {DrillHoleFilterByProgress} from "../../../models/demo/filter/DrillHoleFilterByProgress";

interface DrillHoleViewerFilterByProgressComponentProgress {
    container: DrillHoleAssayViewerContainer,
    applyFilter?: (filter: DrillHoleFilterAbstract) => void
}

export const DrillHoleViewerFilterByProgressComponent: FC<DrillHoleViewerFilterByProgressComponentProgress> = (props) => {

    const [isAvailable, setIsAvailable] = useState<boolean>(false)

    const [minProgress, setMinProgress] = useState<number>(0)
    const [maxProgress, setMaxProgress] = useState<number>(100)

    const [currentProgressRange, setCurrentProgressRange] =
        useState<number[]>([0, 100])
    const [progressRange, setProgressRange] = useState<number[]>([0, 100])

    useEffect(() => {
        if (!isAvailable) {
            if (props.applyFilter)
                props.applyFilter(new DrillHoleFilterAllVisible())
            return
        }
        const minProgress = progressRange[0]
        const maxProgress = progressRange[1]
        if (props.applyFilter)
            props.applyFilter(new DrillHoleFilterByProgress(minProgress, maxProgress))

    }, [progressRange, isAvailable])


    useEffect(() => {
        const range = props.container.getRange()
        setMinProgress(range.minProgressPercentage)
        setMaxProgress(range.maxProgressPercentage)

        setCurrentProgressRange([range.minProgressPercentage, range.maxProgressPercentage])
        setProgressRange([range.minProgressPercentage, range.maxProgressPercentage])
    }, [])


    return <Stack direction='row' spacing={spacing} alignItems='center'>
        <Checkbox checked={isAvailable} onChange={event => {
            setIsAvailable(event.target.checked);
        }}/>

        <Typography variant='body1'>
            Progreso
        </Typography>
        <Slider disabled={!isAvailable} size="small" min={minProgress} max={maxProgress}
                defaultValue={currentProgressRange}
                value={currentProgressRange}
                sx={{width: 300}}

                onChange={(event, value) => {
                    setCurrentProgressRange(value as number[])
                }}
                onChangeCommitted={(event, value) => {
                    setProgressRange(value as number[])
                }}
                valueLabelDisplay="auto" step={0.1}
                marks={[{value: minProgress, label: `${minProgress}%`}, {value: maxProgress, label: `${maxProgress}%`}]}
        />
    </Stack>
}