import {Route, Routes} from "react-router-dom";
import {UserDetailsComponent} from "./components/global/userDetails/userDetailsComponent";
import {EpipremnumCreateComponent} from "./components/epipremnum/epipremnumCreate/epipremnumCreateComponent";
import {EpipremnumAllComponent} from "./components/epipremnum/epipremnumAll/epipremnumAllComponent";
import {
    EpipremnumRawPictureCreateComponent
} from "./components/epipremnumRawPicture/epipremnumRawPictureCreate/epipremnumRawPictureCreateComponent";
import {
    EpipremnumRawPictureDetailsComponent
} from "./components/epipremnumRawPicture/epipremnumCuttingRawPictureDetails/epipremnumRawPictureDetailsComponent";
import {GenericNotFoundedComponent} from "./components/global/genericNotFounded/genericNotFoundedComponent";
import * as React from "react";
import {useSelector} from "react-redux";
import {RootState} from "./redux/store";
import {EpipremnumScanComponent} from "./components/epipremnum/epipremnumScan/epipremnumScanComponent";
import {EpipremnumDetailsRoute} from "./components/global/privateRoute/epipremnumDetailsRoute";
import {EpipremnumEditComponent} from "./components/epipremnum/epipremnumDetails/epipremnumEditComponent";
import {Strings} from "./Resources/Strings";
import {
    EpipremnumRawPictureCreateCommercialPictureComponent
} from "./components/epipremnumRawPicture/epipremnumRawPictureCreateCommercialPicture/epipremnumRawPictureCreateCommercialPictureComponent";
import {ReportsComponent} from "./components/report/reports/reportsComponent";
import {
    MercadoLibrePendingOrdersComponent
} from "./components/report/mercadoLibrePendingOrders/mercadoLibrePendingOrdersComponent";
import {PlantEditComponent} from "./components/plant/plantEditComponent";
import {PlantCreateImageComponent} from "./components/plant/plantCreateImageComponent";
import {Box, CircularProgress, Grid} from "@mui/material";
import {margin} from "./Resources/styles";
import {StoragePlantInCubicleComponent} from "./components/storage/StoragePlantInCubicleComponent";
import {PlantSheetCollectionComponent} from "./components/plant/PlantSheetCollectionComponent";
import {PlantCollectionComponent} from "./components/plant/plantCollectionComponent";
import {PlantDetailsComponent} from "./components/plant/plantDetailsComponent";
import {PlantStorageViewerComponent} from "./components/storage/PlantStorageViewerComponent";
import {PhaseViewerComponent} from "./components/demo/phaseViewer/PhaseViewerComponent";
import {
    AnonymousEpipremnumDetailsComponent
} from "./components/anonymous/anonymousEpipremnum/anonymousEpipremnumDetailsComponent";
import {TopographyViewerComponent} from "./components/demo/topographyViewer/topographyViewerComponent";
import {DrillHoleViewerComponent} from "./components/demo/drillHoleViewer/DrillHoleViewerComponent";
import {DrillHoleAllComponent} from "./components/demo/DrillHoleTable/DrillHoleAllComponent";
import {
    DrillHoleCreateCampaignComponent
} from "./components/demo/DrillHoleInsertCreateCampaign/DrillHoleCreateCampaignComponent";
import useScreenSize from "./hooks/useScreenSize";
import {TestViewerComponent} from "./components/demo/TestViewer/TestViewerComponent";

export const RoutesComponent = () => {

    const {width, height} = useScreenSize()

    const {isInProgress} = useSelector((root: RootState) => root.progress);

    return <Box margin={margin} sx={{pointerEvents: isInProgress ? 'none' : 'auto', overflow: 'hidden'}}>
        <Routes>
            {/*<Route path="/" element={<AnonymousEpipremnumDetailsComponent/>}/>*/}
            <Route path="/" element={<TestViewerComponent/>}/>

            {/*Plant Components*/}
            <Route path="/plant/selectCubicle/:plantId" element={<StoragePlantInCubicleComponent/>}/>
            <Route path="/plant/shelf/:plantId" element={<PlantStorageViewerComponent/>}/>
            <Route path="/plant/Edit/:id" element={<PlantEditComponent/>}/>
            <Route path="/plant/:id" element={<PlantDetailsComponent/>}/>

            <Route path="/plant/sheet" element={<PlantSheetCollectionComponent/>}></Route>
            <Route path="/plant/all" element={<PlantCollectionComponent/>}></Route>

            <Route path="/plantImage/create/:plantId" element={<PlantCreateImageComponent/>}/>

            <Route path="/userdetails" element={<UserDetailsComponent/>}/>

            <Route path="/epipremnum/create" element={<EpipremnumCreateComponent/>}/>
            <Route path="/epipremnum/scan" element={<EpipremnumScanComponent/>}/>
            <Route path="/epipremnum/:id" element={<EpipremnumDetailsRoute/>}/>
            <Route path="/epipremnum/all" element={<EpipremnumAllComponent/>}/>
            <Route path="/epipremnum/edit/:id" element={<EpipremnumEditComponent/>}/>

            <Route path="/epipremnumRawPicture/create/:epipremnumId"
                   element={<EpipremnumRawPictureCreateComponent/>}/>
            <Route path="/epipremnumRawPicture/:id"
                   element={<EpipremnumRawPictureDetailsComponent/>}/>
            <Route path="/epipremnumRawPicture/create_commercial_picture/:id"
                   element={<EpipremnumRawPictureCreateCommercialPictureComponent/>}/>

            <Route path="/report"
                   element={<ReportsComponent/>}/>

            <Route path="/mercadoLibrePendingOrders"
                   element={<MercadoLibrePendingOrdersComponent/>}/>

            <Route path="/demo"
                   element={<PhaseViewerComponent/>}/>

            <Route path="/demo/drills"
                   element={<DrillHoleViewerComponent/>}/>

            <Route path="/demo/drills/table"
                   element={<DrillHoleAllComponent/>}/>

            <Route path="/demo/drills/create"
                   element={<DrillHoleCreateCampaignComponent/>}/>

            <Route path="/demo/large_topography"
                   element={<TopographyViewerComponent
                       topographyUrl={"https://storage.googleapis.com/epipremnum-development/template/output.csv"}/>}/>

            <Route path="/demo/small_topography"
                   element={<TopographyViewerComponent
                       topographyUrl={"https://storage.googleapis.com/epipremnum-development/template/output_mini.csv"}/>}/>


            <Route path="*" element={<GenericNotFoundedComponent/>}/>
            <Route path="/forbidden" element={<>{Strings.Forbidden}</>}/>
        </Routes>
    </Box>



}
