import {Point} from "jsqr/dist/locator";
import {VideoPlantQrLocation} from "./videoPlantQrLocation";
import {MathUtils} from "three";
import {ImagePlantQrLocation} from "./imagePlantQrLocation";

export class QrCodeDrawing {
    private context?: CanvasRenderingContext2D
    private center: Point
    private currentCenter: Point

    private width: number = 80;
    private currentWidth: number = 0

    private height: number = 80;
    private currentHeight: number = 0

    private angleRadians = 0;
    private currentAngleRadians = 0;

    private durationAnimation = 2000;

    private gapFactorWidth: number = 0.3;
    private gapFactorHeight: number = 0.3;

    private lastTimeMilliseconds: number = 0;

    private strokeColorR: number = 0;
    private strokeColorG: number = 127;
    private strokeColorB: number = 0;

    private lineWidth: number = 5;

    private visible: boolean = true

    constructor(x: number, y: number) {
        this.center = {x: x, y: y}
        this.currentCenter = {x: x, y: y}
    }

    updateBasedQrLocation(qrLocation: VideoPlantQrLocation, videoWidth: number, videoHeight: number) {

        if (!this.context)
            return

        let realQrLocation = qrLocation.toImageQrLocation(videoWidth, videoHeight,
            this.context.canvas.width, this.context.canvas.height)

        this.angleRadians = qrLocation.getAngleRadians()

        let worldLeftCorner = realQrLocation.orthogonalLeftCorner
        let worldRightCorner = realQrLocation.orthogonalRightCorner

        this.setCenter((worldLeftCorner.x + worldRightCorner.x) / 2, (worldLeftCorner.y + worldRightCorner.y) / 2)
        this.width = worldRightCorner.x - worldLeftCorner.x
        this.height = worldRightCorner.y - worldLeftCorner.y

        return realQrLocation;
    }

    setFactor(gapFactor: number) {
        this.gapFactorWidth = gapFactor
        this.gapFactorHeight = gapFactor
    }

    setContext(context: CanvasRenderingContext2D): void {
        this.context = context
    }

    setCenter(x: number, y: number): void {
        this.center = {x: x, y: y}
    }

    setWidth(width: number): void {
        this.width = width
    }

    setHeight(height: number): void {
        this.height = height
    }

    setColor(r: number, g: number, b: number) {
        this.strokeColorR = r
        this.strokeColorG = g
        this.strokeColorB = b
    }

    setLineWidth(lineWidth: number) {
        this.lineWidth = lineWidth
    }

    setVisible(visible: boolean) {
        this.visible = visible
    }


// noinspection JSSuspiciousNameCombination
    update(currentTimeMilliseconds: number) {
        if (!this.center || !this.context || !this.visible)
            return

        let deltaTime = currentTimeMilliseconds - this.lastTimeMilliseconds
        this.lastTimeMilliseconds = currentTimeMilliseconds;
        let animationStep = currentTimeMilliseconds % this.durationAnimation
        let value = Math.sin(MathUtils.mapLinear(animationStep, 0, this.durationAnimation, 0, 2 * Math.PI))
        let alpha = MathUtils.mapLinear(value, -1, 1, 0.2, 1);

        // Create item
        let center_x = MathUtils.damp(this.currentCenter.x, this.center.x, .007, deltaTime);
        let center_y = MathUtils.damp(this.currentCenter.y, this.center.y, .007, deltaTime);
        let width = MathUtils.damp(this.currentWidth, this.width, .01, deltaTime);
        let height = MathUtils.damp(this.currentHeight, this.height, .01, deltaTime);
        let angleRadians = MathUtils.damp(this.currentAngleRadians, this.angleRadians, .01, deltaTime);


        this.currentWidth = width
        this.currentHeight = height
        this.currentCenter = {x: center_x, y: center_y}
        this.currentAngleRadians = angleRadians

        let combinations: Point[] = [{x: +1, y: +1}, {x: -1, y: -1}, {x: +1, y: -1}, {x: -1, y: +1}]


        for (const combination of combinations) {

            let horizontalSign = combination.x;
            let verticalSign = combination.y;

            // Build first
            let p0 = {
                x: +horizontalSign * this.currentWidth / 2,
                y: +verticalSign * this.gapFactorHeight * this.currentHeight / 2,
            }

            let p1 = {
                x: +horizontalSign * this.currentWidth / 2,
                y: +verticalSign * this.currentHeight / 2,
            }

            let p2 = {
                x: +horizontalSign * this.gapFactorWidth * this.currentWidth / 2,
                y: +verticalSign * this.currentHeight / 2,
            }

            // Line
            this.context.beginPath()
            this.context.translate(center_x - width / 2, center_y - height / 2)
            this.context.rotate(angleRadians)
            this.context.translate(width / 2, height / 2)

            this.context.moveTo(p0.x, p0.y);
            this.context.lineTo(p1.x, p1.y);
            this.context.lineTo(p2.x, p2.y);
            this.context.strokeStyle = `rgba(${this.strokeColorR},${this.strokeColorG},${this.strokeColorB},${alpha})`
            this.context.lineWidth = this.lineWidth
            this.context.stroke();
            this.context.resetTransform()
        }
    }
}