import {
    Box3,
    BoxGeometry,
    BufferAttribute,
    BufferGeometry,
    DoubleSide,
    Mesh,
    MeshStandardMaterial, Vector3
} from "three";
import {getDiagonal, getMiddle, getSize} from "./threeUtils";
import {IPlantShelfModel} from "../../api/IPlantShelfModel";
import {ShelfReader} from "./ShelfReader";
import {IPlantShelfCubicleModel} from "../../api/IPlantShelfCubicleModel";
import {IPlantModel} from "../../api/IPlantModel";
import {Base3dViewerManager} from "../../components/dataTemplate/viewer/Base3dViewnerManager";


interface StorageComponentViewerManagerProps {
    multipleSelectable: boolean,
    disableSelectable?: boolean,
    onMouseOverCubicle?: (cubicle: IPlantShelfCubicleModel) => void,
    onSelectedCubicle?: (cubicle: IPlantShelfCubicleModel) => void,
    onUnselectedCubicle?: (cubicle: IPlantShelfCubicleModel) => void,
}

interface IShelfCubicleState {
    color: string,
    scale: number,
    opacity: number,
}

export class StorageComponentViewerManager extends Base3dViewerManager {
    private readonly _cubicleKey = "cubicle"
    private readonly _modelKey = "model"
    private readonly _shelfKey = "shelf"
    private readonly _selectedKey = "selected"
    private readonly _hasPlantKey = "hasPlant"
    private readonly _plantModelKey = "plant"
    private _props: StorageComponentViewerManagerProps = {
        multipleSelectable: true
    }

    private _baseState: IShelfCubicleState = {
        opacity: 0.8,
        scale: 0.8,
        color: "rgb(0,0,127)"
    }
    private _overState: IShelfCubicleState = {
        opacity: 1,
        scale: 0.9,
        color: "rgb(127,0,0)"
    }

    private _selectedState: IShelfCubicleState = {
        opacity: 1,
        scale: 0.95,
        color: "rgb(218,220,0)"
    }
    private _plantState: IShelfCubicleState = {
        opacity: 1,
        scale: 0.95,
        color: "rgb(0,127,0)"
    }

    constructor() {
        super();

    }

    public setProps(props: StorageComponentViewerManagerProps) {
        this._props = props;
    }


    public async setItems(shelf: IPlantShelfModel, shelfCubicleModels: IPlantShelfCubicleModel[], plantModels: IPlantModel[]) {
        this.clear()

        const trianglesReader = new ShelfReader()
        trianglesReader.loadData(shelf)

        const geometry = new BufferGeometry();
        geometry.setAttribute("position", new BufferAttribute(trianglesReader.vertices, 3))
        geometry.setAttribute("color", new BufferAttribute(trianglesReader.colors, 3))
        geometry.computeVertexNormals()
        geometry.computeBoundingBox()
        const material = new MeshStandardMaterial({
            side: DoubleSide,
            vertexColors: true
        })
        const mainMesh = new Mesh(geometry, material)

        const bbox = geometry.boundingBox!
        let lookAtPosition = getMiddle(bbox)
        let diagonal = getDiagonal(bbox)

        this.controls.target.set(lookAtPosition.x, lookAtPosition.y, lookAtPosition.z)
        this.camera.position.set(lookAtPosition.x, lookAtPosition.y, lookAtPosition.z + diagonal)

        mainMesh.userData[this._shelfKey] = true
        this.scene.add(mainMesh)

        // Add boxes
        for (let shelfCubicleModel of shelfCubicleModels) {
            // Check if it has Plant
            let hasPlant = false
            let userPlantModel: IPlantModel | undefined = undefined


            for (const plantModel of plantModels)
                for (const cubicleRef of plantModel.storedInCubicles)
                    if (cubicleRef._id === shelfCubicleModel.id) {
                        hasPlant = true
                        userPlantModel = plantModel
                    }


            const minBox = new Vector3(shelfCubicleModel.minX, shelfCubicleModel.minY, shelfCubicleModel.minZ)
            const maxBox = new Vector3(shelfCubicleModel.maxX, shelfCubicleModel.maxY, shelfCubicleModel.maxZ)
            const bbox = new Box3(minBox, maxBox)

            const size = getSize(bbox)
            const middle = getMiddle(bbox)

            const cubicleGeometry = new BoxGeometry(size.x, size.y, size.z)
            const cubicleMaterial = new MeshStandardMaterial({
                transparent: true,
            })

            const meshBox = new Mesh(cubicleGeometry, cubicleMaterial)


            meshBox.addEventListener("mouseover", event => {

                // document.body.style.cursor = "crosshair"

                if (meshBox.userData[this._selectedKey] == true)
                    return

                // Check if it has Plant
                if (meshBox.userData[this._hasPlantKey] === true) return;

                this.setState(meshBox, this._overState)
                if (this._props.onMouseOverCubicle)
                    this._props.onMouseOverCubicle(meshBox.userData[this._modelKey] as IPlantShelfCubicleModel)
            })

            meshBox.addEventListener("mouseout", event => {
                document.body.style.cursor = "auto"

                if (meshBox.userData[this._selectedKey] == true)
                    return
                // Check if it has Plant
                if (meshBox.userData[this._hasPlantKey] === true) return;

                this.setState(meshBox, this._baseState)
            })

            meshBox.addEventListener("click", event => {
                if (this._props.disableSelectable) return;
                if (this.interactionManager.closestObject === null) return;
                if (meshBox !== this.interactionManager.closestObject.target)
                    return;

                // Check if it has Plant
                if (meshBox.userData[this._hasPlantKey] === true) return;

                let id = (meshBox.userData[this._modelKey] as IPlantShelfCubicleModel).id
                if (meshBox.userData[this._selectedKey] === true) {
                    this.unselectCubicle(id)
                } else {
                    this.selectCubicle(id)
                }
            })

            this.interactionManager.add(meshBox)
            meshBox.position.set(middle.x, middle.y, middle.z)
            meshBox.userData[this._cubicleKey] = true
            meshBox.userData[this._modelKey] = shelfCubicleModel
            meshBox.userData[this._selectedKey] = false
            meshBox.userData[this._hasPlantKey] = hasPlant
            meshBox.userData[this._plantModelKey] = userPlantModel

            if (hasPlant) {
                this.setState(meshBox, this._plantState)
            } else {
                this.setState(meshBox, this._baseState)
            }
            this.scene.add(meshBox)
        }
    }

    public clear() {
        for (let i = this.scene.children.length - 1; i >= 0; i--) {
            const child = this.scene.children[i];
            if (child.userData[this._cubicleKey] || child.userData[this._shelfKey])
                this.scene.remove(child);
        }
    }

    public selectCubicle(cubicleId: string) {
        let selectedCubicle: IPlantShelfCubicleModel | undefined = undefined
        let unselectedCubicles: IPlantShelfCubicleModel[] = []

        for (let cubicleMesh of this.getCubicles()) {
            let shelfCubicle = cubicleMesh.userData[this._modelKey] as IPlantShelfCubicleModel;
            if (cubicleId === shelfCubicle.id) {
                this.setState(cubicleMesh, this._selectedState)
                cubicleMesh.userData[this._selectedKey] = true
                selectedCubicle = shelfCubicle
            } else {

                if (this._props.multipleSelectable) continue

                if (cubicleMesh.userData[this._selectedKey] === true) {
                    this.setState(cubicleMesh, this._baseState)
                    cubicleMesh.userData[this._selectedKey] = false
                    unselectedCubicles.push(shelfCubicle)
                }
            }
        }

        if (!selectedCubicle)
            throw new Error("Invalid Cubicle ID")
        if (this._props.onSelectedCubicle) this._props.onSelectedCubicle(selectedCubicle)
    }


    public unselectCubicle(cubicleId: string) {
        let selectedCubicle: IPlantShelfCubicleModel | undefined = undefined

        for (let cubicleMesh of this.getCubicles()) {
            let shelfCubicle = cubicleMesh.userData[this._modelKey] as IPlantShelfCubicleModel;
            if (cubicleId === shelfCubicle.id) {
                this.setState(cubicleMesh, this._baseState)
                cubicleMesh.userData[this._selectedKey] = false
                selectedCubicle = shelfCubicle
            }
        }
        if (!selectedCubicle)
            throw new Error("Invalid Cubicle ID")
        if (this._props.onUnselectedCubicle) this._props.onUnselectedCubicle(selectedCubicle)
    }

    public getCubicles() {
        let meshes: Mesh<BoxGeometry, MeshStandardMaterial>[] = []
        for (let i = this.scene.children.length - 1; i >= 0; i--) {
            const child = this.scene.children[i];
            if (child.userData[this._cubicleKey])
                meshes.push(child as Mesh<BoxGeometry, MeshStandardMaterial>)
        }
        return meshes
    }

    private setState(mesh: Mesh<BoxGeometry, MeshStandardMaterial>, state: IShelfCubicleState) {
        mesh.material.color.set(state.color)
        mesh.material.opacity = state.opacity
        mesh.scale.set(state.scale, state.scale, state.scale)
    }
}