import {apiUrl, apiUtils} from "../ApiConfig";
import {IDrillHoleCollarModel} from "../../models/demo/IDrillHoleCollarModel";
import {IDrillHoleCollarClientModel} from "../../models/demo/IDrillHoleCollarClientModel";

class DrillHoleService {

    async read() {
        const requestInit = apiUtils.getBaseRequest("GET")

        const url = `${apiUrl}drillHoleCollar`
        const response = await fetch(url, requestInit)

        return await response.json() as IDrillHoleCollarModel[]
    }

    async readClientModels() {
        const requestInit = apiUtils.getBaseRequest("GET")

        const url = `${apiUrl}drillHoleCollar/client_assay`
        const response = await fetch(url, requestInit)

        return await response.json() as IDrillHoleCollarClientModel[]
    }

    async downloadReport() {
        const requestInit = apiUtils.getBaseRequest("POST")

        const url = `${apiUrl}drillHoleCollar/test_report`

        const body = {}
        requestInit.body = JSON.stringify(body)
        const response = await fetch(url, requestInit)
        const blob = await response.blob()
        apiUtils.downloadBlob(blob, "drill_report.xlsx")
    }
}

export const drillHoleService = new DrillHoleService()