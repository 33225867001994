import React, {useRef, useState} from "react";
import {Button, Checkbox, IconButton, List, ListItemButton} from "@mui/material";
import {PhaseViewerManager} from "../../../logic/Viewer3D/PhaseViewerManager";
import {Color} from "three";
import {setInProgressOffThunk, setInProgressOnThunk} from "../../../redux/progress";
import {store} from "../../../redux/store";
import {phaseService} from "../../../api/demo/phaseService";
import {IPhaseNumberVisible} from "./IPhaseNumberVisible";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ViewInArIcon from "@mui/icons-material/ViewInAr";
import {ViewerDataTemplateComponent} from "../../dataTemplate/viewer/ViewerDataTemplateComponent";


export const PhaseViewerComponent = () => {
    const mountRef = useRef(null)

    const [manager] = useState<PhaseViewerManager>(() => new PhaseViewerManager())


    const [phaseVisibleCollection, setPhaseVisibleCollection] =
        useState<IPhaseNumberVisible[]>([])

    const [isTopographyVisible, setIsTopographyVisible] =
        useState<boolean>(true)

    const initViewerCallback = async () => {
        await store.dispatch(setInProgressOnThunk())

        let items = await phaseService.getItems()
        manager.scene.background = new Color(0.5, 0.5, 0.5)

        for (let item of items) {
            await manager.addItem(item)
        }

        await store.dispatch(setInProgressOffThunk())
        manager.fitCamera()

        const currentPhaseNumbers: IPhaseNumberVisible[] = []
        for (let phaseNumber of manager.getPhaseNumbers()) {
            currentPhaseNumbers.push({
                isVisible: false,
                number: phaseNumber
            })
        }
        setPhaseVisibleCollection(currentPhaseNumbers)
    }

    return <>

        <ViewerDataTemplateComponent manager={manager} viewerInitCallback={initViewerCallback}/>
        <Button variant='outlined' onClick={() => {
            manager.fitCamera()
        }}>Fit </Button>

        <List>
            <ListItemButton dense>
                <ListItemIcon>
                    <Checkbox
                        onClick={() => {
                            setIsTopographyVisible(!isTopographyVisible)
                            manager.setTopographyVisible(!isTopographyVisible)
                        }}
                        edge="start"
                        checked={isTopographyVisible}
                        tabIndex={-1}
                        disableRipple
                    />
                </ListItemIcon>
                <ListItemText primary={`Topografía Visible`}/>
            </ListItemButton>

            {phaseVisibleCollection.map((value) => {
                return (
                    <ListItem dense
                              key={value.number}
                              secondaryAction={
                                  <IconButton edge="end" aria-label="comments">
                                      <ViewInArIcon/>
                                  </IconButton>
                              } disablePadding>

                        <ListItemButton dense>
                            <ListItemIcon>
                                <Checkbox
                                    onClick={() => {
                                        const index = phaseVisibleCollection.indexOf(value)
                                        phaseVisibleCollection[index].isVisible = !value.isVisible
                                        setPhaseVisibleCollection([...phaseVisibleCollection])

                                        manager.setVisible(phaseVisibleCollection
                                            .filter(item => item.isVisible)
                                            .map(item => item.number))
                                    }}
                                    edge="start"
                                    checked={value.isVisible}
                                    tabIndex={-1}
                                    disableRipple
                                />
                            </ListItemIcon>
                            <ListItemText primary={`Fase ${value.number}`}/>
                        </ListItemButton>
                    </ListItem>
                );
            })}
        </List>
    </>
}