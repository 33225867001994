import {IDrillHoleCollarClientModel} from "./IDrillHoleCollarClientModel";
import {BufferAttribute, BufferGeometry, DoubleSide, Line, LineBasicMaterial} from "three";
import {DrillHoleFilterAllVisible} from "./filter/DrillHoleFilterAllVisible";

export class DrillHoleLineContainer {

    private readonly _collarClient: IDrillHoleCollarClientModel
    private _colorList: number[] = []
    private _vertexList: number[] = []

    private _line: Line

    constructor(collarClient: IDrillHoleCollarClientModel) {
        this._collarClient = collarClient
        this._colorList = []
        this._vertexList = []

        for (let assay of this._collarClient.assay) {

            this._colorList.push(+assay.color.r / 255.0)
            this._colorList.push(+assay.color.g / 255.0)
            this._colorList.push(+assay.color.b / 255.0)

            this._colorList.push(+assay.color.r / 255.0)
            this._colorList.push(+assay.color.g / 255.0)
            this._colorList.push(+assay.color.b / 255.0)

            this._vertexList.push(+assay.from.x)
            this._vertexList.push(+assay.from.y)
            this._vertexList.push(+assay.from.z)

            this._vertexList.push(+assay.to.x)
            this._vertexList.push(+assay.to.y)
            this._vertexList.push(+assay.to.z)
        }


        const geometry = new BufferGeometry();
        geometry.setAttribute("position", new BufferAttribute(new Float32Array(this._vertexList), 3))
        geometry.setAttribute("color", new BufferAttribute(new Float32Array(this._colorList), 3))
        geometry.computeVertexNormals()
        geometry.computeBoundingBox()
        const material = new LineBasicMaterial({
            vertexColors: true
        })

        this._line = new Line(geometry, material)
        // Reset

        const allVisible = new DrillHoleFilterAllVisible()
        this.computeIndices(allVisible.filter(this._collarClient))
    }

    getLine() {
        return this._line
    }

    getCollar() {
        return this._collarClient
    }

    public computeIndices(status: boolean[]) {
        if (this._collarClient.assay.length != status.length)
            throw new Error("Invalid lenth")
        const indices: number[] = []

        for (let i = 0; i < status.length; i++) {
            if (status[i]) {
                indices.push(i * 2)
                indices.push(i * 2 + 1)
            } else {
                indices.push(-1)
            }
        }
        this._line.geometry.setIndex(indices)
    }
}


