import {IPlantImageCreateInput} from "./IPlantImageCreateInput";
import {apiUrl, apiUtils} from "./ApiConfig";
import {IPlantImageModel} from "./IPlantImageModel";


interface IReadPlantImagesFilter {
    ids?: string[],
    plantIds?: string[]
}


class PlanImageService {
    async createAsync(plantId: string, base64Image: string, description?: string) {
        let body: IPlantImageCreateInput = {
            plantId: plantId,
            base64Image: base64Image,
            description: description
        }
        const requestOptions = apiUtils.getBaseRequest('POST')
        requestOptions.body = JSON.stringify(body)

        let uri = `${apiUrl}plantImage`
        let response = await fetch(uri, requestOptions);
        return await response.json() as IPlantImageModel;
    }

    async deleteAsync(id: string) {
        const requestOptions = apiUtils.getBaseRequest('DELETE')
        let uri = `${apiUrl}plantImage/${id}`
        let response = await fetch(uri, requestOptions);
        return await response.json() as IPlantImageModel;
    }

    async readOneAsync(plantImageId: string) {
        const requestOptions = apiUtils.getBaseRequest('GET')

        let uri = `${apiUrl}plantImage/${plantImageId}`
        let response = await fetch(uri, requestOptions);
        return await response.json() as IPlantImageModel;
    }

    async readAsync(filter: IReadPlantImagesFilter) {

        const requestOptions = apiUtils.getBaseRequest('GET')
        let uri = `${apiUrl}plantImage?`
        if (filter) {

            if (filter.ids && filter.ids.length > 0) {
                for (let id of filter.ids) {
                    uri += `Ids=${id}&`
                }
            }
            if (filter.plantIds && filter.plantIds.length > 0) {
                for (let plantId of filter.plantIds) {
                    uri += `PlantIds=${plantId}&`
                }
            }
        }
        let response = await fetch(uri, requestOptions);
        return await response.json() as IPlantImageModel[];
    }
}

export const plantImageService = new PlanImageService()

