import {IPlantShelfModel} from "../../api/IPlantShelfModel";
import {readShapeTriangles} from "./threeUtils";

export class ShelfReader {

    vertices: Float32Array = new Float32Array([]);
    colors: Float32Array = new Float32Array([]);

    constructor() {

    }

    public loadData(shelf: IPlantShelfModel) {

        const text = shelf.shapeBase64

        const verticesAndColors = readShapeTriangles(text)

        this.vertices = verticesAndColors.vertices
        this.colors = verticesAndColors.colors

    }
}