import {Vector2} from "three";

export class ImagePlantQrLocation {
    leftCorner: Vector2;
    rightCorner: Vector2;
    bottomRightAlignment?: Vector2;
    orthogonalLeftCorner: Vector2;
    orthogonalRightCorner: Vector2;
    angleFromXRadians: number;
    videoLeftCorner: Vector2;
    videoRightCorner: Vector2;
    videoBottomRightAlignment?: Vector2;

    constructor(leftCorner: Vector2, rightCorner: Vector2, orthogonalLeftCorner: Vector2, orthogonalRightCorner: Vector2,
                angleFromXRadians: number, videoLeftCorner: Vector2, videoRightCorner: Vector2,
                bottomRightAlignment?: Vector2, videoBottomRightAlignment?: Vector2) {
        this.leftCorner = leftCorner
        this.rightCorner = rightCorner
        this.bottomRightAlignment = bottomRightAlignment

        this.orthogonalLeftCorner = orthogonalLeftCorner
        this.orthogonalRightCorner = orthogonalRightCorner
        this.angleFromXRadians = angleFromXRadians
        this.videoLeftCorner = videoLeftCorner
        this.videoRightCorner = videoRightCorner
        this.videoBottomRightAlignment = videoBottomRightAlignment
    }
}