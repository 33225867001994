import {apiUrl, apiUtils} from "./ApiConfig";
import {Moment} from "moment/moment";
import {ICreateEpipremnumRawPicture} from "./ICreateEpipremnumRawPicture";
import {IEpipremnumRawPicture} from "./IEpipremnumRawPicture";
import {IEpipremnumRawPictureOutput} from "./IEpipremnumRawPictureOutput";
import {IEpipremnumRelatedPictures} from "./IEpipremnumRelatedPictures";
import {CommercialPictureConfiguration} from "./commercialPictureConfiguration";

class EpipremnumRawPictureService {
    async createAsync(epipremnumId: string,
                      width: number,
                      height: number,
                      pictureDate: Moment,
                      pngPictureUrl: string,
                      description: string) {

        let body: ICreateEpipremnumRawPicture = {
            epipremnumId: epipremnumId,
            height: height,
            pictureDate: pictureDate.toISOString(),
            pngPictureUrl: pngPictureUrl,
            width: width,
            description: description
        }

        const requestOptions = apiUtils.getBaseRequest('POST')
        requestOptions.body = JSON.stringify(body)


        let response = await fetch(`${apiUrl}epipremnumRawPicture`, requestOptions);
        return await response.json() as IEpipremnumRawPicture;
    }

    async createAlpha(id: string, pictureDate: Moment) {
        let body = {
            rawPictureReferenceId: id,
            pictureDate: pictureDate.toISOString(),
        }

        const requestOptions = apiUtils.getBaseRequest('POST')
        requestOptions.body = JSON.stringify(body)


        let response = await fetch(`${apiUrl}epipremnumRawPicture/alpha`, requestOptions);
        return await response.json() as IEpipremnumRawPicture;
    }

    async deleteAsync(id: string) {
        const requestOptions = apiUtils.getBaseRequest('DELETE')
        let response = await fetch(`${apiUrl}epipremnumRawPicture/${id}`, requestOptions);
        return await response.json() as IEpipremnumRawPicture;
    }

    async readAsync(id: string) {
        const requestOptions = apiUtils.getBaseRequest('GET')
        let response = await fetch(`${apiUrl}epipremnumRawPicture/${id}`, requestOptions);
        return await response.json() as IEpipremnumRawPictureOutput;
    }

    async readEpipremnumPicturesAsync(epipremnumId: string) {
        const requestOptions = apiUtils.getBaseRequest('GET')
        let response = await fetch(`${apiUrl}epipremnumRawPicture/epipremnum/${epipremnumId}`, requestOptions);
        return await response.json() as IEpipremnumRawPictureOutput[];
    }

    async readContent(id: string) {
        const requestOptions = apiUtils.getBaseRequest('GET')
        let response = await fetch(`${apiUrl}epipremnumRawPicture/content/${id}`, requestOptions);
        return await response.text();
    }

    async getRelatedPictures(id: string): Promise<IEpipremnumRelatedPictures> {
        const requestOptions = apiUtils.getBaseRequest('GET')
        let response = await fetch(`${apiUrl}epipremnumRawPicture/related_pictures/${id}`, requestOptions);
        return await response.json() as IEpipremnumRelatedPictures
    }

    async previewTransparentPicture(alphaPictureId: string, configuration: CommercialPictureConfiguration): Promise<string> {
        const requestOptions = apiUtils.getBaseRequest('GET')

        let query = `${apiUrl}epipremnumRawPicture/commercial_preview_transparent?AlphaPictureReferenceId=${alphaPictureId}`
        query += configuration.getConfiguration()


        let response = await fetch(query, requestOptions);
        let blob = await response.blob()
        return await apiUtils.getBase64(blob)
    }

    getCommercialBackgrounds(): string[] {
        let urlList: string[] = []
        for (let i = 1; i <= 10; i++) {
            urlList.push(`https://storage.googleapis.com/epipremnum-production/background/${i}.png`)
        }
        return urlList
    }

    async previewBackgroundPicture(alphaPictureId: string, configuration: CommercialPictureConfiguration): Promise<string> {
        const requestOptions = apiUtils.getBaseRequest('GET')

        let query = `${apiUrl}epipremnumRawPicture/commercial_preview_background?AlphaPictureReferenceId=${alphaPictureId}`
        query += configuration.getConfiguration()

        let response = await fetch(query, requestOptions);
        let blob = await response.blob()
        return await apiUtils.getBase64(blob)
    }

    async createCommercialPictures(alphaPictureId: string, configuration: CommercialPictureConfiguration): Promise<void> {
        const requestOptions = apiUtils.getBaseRequest('POST')
        let query = `${apiUrl}epipremnumRawPicture/commercial?AlphaPictureReferenceId=${alphaPictureId}`
        query += configuration.getConfiguration()
        let response = await fetch(query, requestOptions);
    }
}


export const epipremnumRawPictureService = new EpipremnumRawPictureService()


