export const spacing: number = 2
export const margin: number = 2
export const padding: number = 2
export const elevation: number = 2

export const thumbnailPixels: number = 512

export const imageStyle = {
    style: {
        borderRadius: '5%',
        fit:'contain'
    }
}
