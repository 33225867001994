import {useEffect, useState} from "react";
import {Alert, Button, Grid, TextField, Typography} from "@mui/material";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import moment, {Moment} from "moment";
import {Strings} from "../../../Resources/Strings";
import {margin, spacing} from "../../../Resources/styles";
import Box from "@mui/material/Box";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import LoadingButton from "@mui/lab/LoadingButton";
import {store} from "../../../redux/store";
import {deselectEpipremnumThunk} from "../../../redux/epipremnum";
import {SnackbarKey, useSnackbar} from 'notistack'
import {useNavigate} from "react-router-dom";
import {epipremnumService} from "../../../api/epipremnumService";


export const EpipremnumCreateComponent = () => {
    const [plantDate, setPlantDate] = useState<Moment>(moment());
    const [description, setDescription] = useState<string>("");
    const {enqueueSnackbar, closeSnackbar} = useSnackbar()
    const [isCreating, setIsCreating] = useState<boolean>()
    const navigate = useNavigate()

    useEffect(() => {
        store.dispatch(deselectEpipremnumThunk())
    }, [])

    const addEpipremnumCallback = async () => {
        setIsCreating(true)
        let epipremnumModel = await epipremnumService.createAsync(plantDate, description);
        setIsCreating(false)

        let id = epipremnumModel.id
        enqueueSnackbar(`${Strings.CreatedEpipremnum}`, {
            content: (key, message) => {
                return <div key={key}>
                    <Alert severity='success' action={<Grid container spacing={spacing}>
                        <Grid item>
                            <Button color='warning' size='small' variant='outlined'
                                    onClick={async () => {
                                        await undoEpipremnumCreationCallback(key, id)
                                    }}>
                                {Strings.Undo}
                            </Button>
                        </Grid>
                    </Grid>}>{message}</Alert>
                </div>
            }
        })
        navigate(`/epipremnum/${id}`)
    }

    const onDescriptionChanged = (event: any | HTMLInputElement) => {
        setDescription(event.target.value)
    }

    const undoEpipremnumCreationCallback = async (key: SnackbarKey, id: string) => {
        try {
            closeSnackbar(key)
            await epipremnumService.deleteAsync(id)
            navigate('/epipremnum/create')
            enqueueSnackbar(`${Strings.Deleted}`, {
                content: (key, message) => {
                    return <div key={key}>
                        <Alert severity='warning' onClose={() => closeSnackbar(key)}>{message}</Alert>
                    </div>
                }
            })
        } catch (e) {
            console.error(e)
        }
    }

    return <Box m={margin}>
        <Grid spacing={spacing} container direction="row"
              justifyContent="space-evenly"
              alignItems="center">
            <Grid item xs={12}>
                <Typography variant='h6' fontWeight='bold'>{Strings.CreateEpipremnum}</Typography>
            </Grid>

            <Grid item xs={12}>
                <TextField
                    fullWidth
                    label={Strings.Description}
                    value={description} onChange={onDescriptionChanged}
                    multiline
                    minRows={4}
                    maxRows={10}
                    variant="outlined"
                />
            </Grid>

            <Grid item xs='auto'>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                        label={Strings.PlantDate}
                        value={plantDate}
                        onChange={(newValue) => {
                            if (newValue)
                                setPlantDate(newValue);
                        }}
                        renderInput={(params) => <TextField {...params} />}
                    />
                </LocalizationProvider>
            </Grid>

            <Grid item xs>

            </Grid>

            <Grid item xs>
                <LoadingButton loading={isCreating} loadingPosition="start"
                               startIcon={<AddCircleIcon/>}
                               onClick={addEpipremnumCallback} fullWidth
                               variant="contained">{Strings.Create}</LoadingButton>
            </Grid>
        </Grid>
    </Box>

}
