import {OrbitControls} from "three/examples/jsm/controls/OrbitControls";
import {
    AmbientLight,
    Box3,
    BufferAttribute,
    BufferGeometry,
    ClampToEdgeWrapping,
    Color,
    DirectionalLight,
    DoubleSide,
    Euler, HemisphereLight,
    Mesh, MeshBasicMaterial, MeshPhongMaterial,
    MeshStandardMaterial,
    Object3D,
    OrthographicCamera,
    PerspectiveCamera,
    PointLight,
    Quaternion,
    RepeatWrapping,
    Scene,
    Sphere, SpotLight,
    TextureLoader,
    Vector2,
    Vector3,
    WebGLRenderer
} from "three";
import {InteractionManager} from "three.interactive";
import {readShapeTriangles} from "../../../logic/Viewer3D/threeUtils";
import ViewCubeControls from "./viewCubeController";
import gsap from "gsap";
import {getEulerFromView} from "./CubeAngles";


export class Base3dViewerManager {

    center = new Vector3(0, 0, 0)
    currentRotation = new Quaternion()

    scene: Scene
    camera: OrthographicCamera
    renderer: WebGLRenderer

    cubeScene: Scene
    cubeCamera: PerspectiveCamera
    cubeRenderer: WebGLRenderer

    interactionManager: InteractionManager;
    controls: OrbitControls;

    refApp?: HTMLElement = undefined
    refCube?: HTMLElement = undefined

    spotLight = new SpotLight('rgb(255,255,255)', 4)


    animation?: () => void


    public constructor() {

        // -- Scene Initialization --
        this.renderer = new WebGLRenderer({alpha: true, antialias: true})

        this.scene = new Scene()
        this.scene.up.set(0, 0, 1)

        this.camera = new OrthographicCamera(-window.innerWidth / 2,
            +window.innerWidth / 2,
            +window.innerHeight / 2,
            -window.innerHeight / 2, -1e8, 1e8)
        this.camera.position.set(0, -10, 0); // Adjust the camera position based on the new coordinate system
        this.camera.up.set(0, 0, 1); // Set the camera's up vector to be in the Z direction
        this.camera.lookAt(0, 0, 0);


        this.scene.add(this.camera)
        this.configureLights()


        window.addEventListener('resize', () => this.resize());


        this.controls = new OrbitControls(this.camera, this.renderer.domElement)
        this.interactionManager = new InteractionManager(
            this.renderer,
            this.camera,
            this.renderer.domElement,
        );
        this.resize()


        let cubeSize = 50
        this.cubeRenderer = new WebGLRenderer({alpha: true});
        this.cubeRenderer.setSize(cubeSize, cubeSize);

        this.cubeScene = new Scene();
        this.cubeScene.up.set(0, 0, 1)

        this.cubeCamera = new PerspectiveCamera(50, 1, 0.1, 1000);
        this.cubeCamera.position.set(0, 0, 65);
        this.cubeCamera.lookAt(0, 0, 0);


        this.controls.addEventListener('change', () => {
            const baseRotation = new Quaternion().setFromEuler(new Euler(-Math.PI / 2, 0, 0))
            let quaternion = this.camera.getWorldQuaternion(new Quaternion())
            quaternion = baseRotation.multiply(quaternion)
            viewCubeControls.setQuaternion(quaternion.invert())
        });

        const viewCubeControls = new ViewCubeControls(this.cubeCamera,
            undefined, undefined, this.cubeRenderer.domElement);
        this.cubeScene.add(viewCubeControls.getObject());

        viewCubeControls.addEventListener('angle-change', ({faceIndex}) => {
            this.setView(faceIndex)
        });


        const update = () => {
            requestAnimationFrame(update);

            this.controls.update()
            viewCubeControls.update();
            this.renderer.render(this.scene, this.camera);


            // console.log(this.camera.position)
            this.spotLight.position.copy(this.camera.position)
            this.cubeRenderer.render(this.cubeScene, this.cubeCamera);
            if (this.animation)
                this.animation()
        }
        update();
    }

    setView(index: number) {
        const euler = getEulerFromView(index)
        let invert = new Quaternion().setFromEuler(euler)

        const fromQuaternion = this.currentRotation
        const toQuaternion = invert

        const boundingBox = new Box3().setFromObject(this.scene);
        const sphere = boundingBox.getBoundingSphere(new Sphere())
        const newZoom = this._getFitZoom(sphere.radius * 2)
        let target = {t: 0, zoom: this.camera.zoom};

        gsap.to(target, {
            t: 1,
            zoom: newZoom,
            duration: 0.5,
            onStart: () => {
                this.controls.enabled = false
            },
            onComplete: () => {
                this.controls.enabled = true
            },
            onUpdate: () => {
                const currentQuaternion = fromQuaternion.clone().slerp(toQuaternion.clone(), target.t)
                let vector = new Vector3(1, 0, 0)
                vector.applyQuaternion(currentQuaternion)
                vector = vector.normalize()
                this.camera.rotation.setFromQuaternion(currentQuaternion)
                this.camera.zoom = target.zoom

                this.camera.position.copy(vector.multiplyScalar(-1).add(this.center));
                this.currentRotation = currentQuaternion
                this.controls.update()
            }
        })
    }

    public _init(refApp: HTMLElement, refCube: HTMLElement) {
        this.refApp = refApp
        this.refApp.appendChild(this.renderer.domElement)

        this.refCube = refCube
        this.refCube.appendChild(this.cubeRenderer.domElement)
    }

    public dispose() {
        window.removeEventListener("resize", this.resize)
    }

    public fitCamera() {
        this.fitCameraFromObject(this.scene)
    }

    public fitCameraFromObject(object: Object3D) {
        if (!this.refApp || !this.refCube) return

        this.resize()

        // Bounding box of all objects
        const boundingBox = new Box3().setFromObject(object);
        const sphere = boundingBox.getBoundingSphere(new Sphere())

        this.setCenter(sphere.center)


        // Size
        const zoom = this._getFitZoom(sphere.radius * 2)
        const currentZoom = this.camera.zoom
        const deltaZoom = zoom - currentZoom

        this.controls.target = sphere.center
        this.camera.zoom = currentZoom + deltaZoom
        this.camera.updateProjectionMatrix()
    }

    private _getFitZoom(diameter: number) {
        const zoomFactorWidth = (this.camera.right - this.camera.left) / diameter
        const zoomFactorHeight = (this.camera.top - this.camera.bottom) / diameter

        let zoom = zoomFactorHeight
        if (zoomFactorWidth < zoomFactorHeight)
            zoom = zoomFactorWidth

        return zoom
    }

    public setAnimation(animation: () => void) {
        this.animation = animation
    }


    public resize() {
        if (!this.refApp || !this.refCube) return

        const {clientWidth: width, clientHeight: height} = this.refApp


        const viewSize = this.renderer.getSize(new Vector2())
        const originalAspect = viewSize.x / viewSize.y;
        const aspect = width / height;
        const change = aspect / originalAspect;
        const newSize = viewSize.x * change;
        this.camera.left = -aspect * newSize / 2;
        this.camera.right = aspect * newSize / 2;
        this.camera.top = newSize / 2;
        this.camera.bottom = -newSize / 2;

        this.camera.updateProjectionMatrix();
        this.renderer.setSize(width, height);

        const rect = this.refApp.getBoundingClientRect()
        const cubeSize = height * 0.25
        this.refCube.style.width = `${cubeSize}px`;
        this.refCube.style.height = `${cubeSize}px`;
        this.refCube.style.top = `${rect.top}px`;
        this.refCube.style.left = `${rect.right - cubeSize}px`;
        this.cubeRenderer.setSize(cubeSize, cubeSize)
    }

    public setCenter(center: Vector3) {
        this.center = center.clone()
        this.controls.target = this.center
        const direction = this.camera.getWorldDirection(new Vector3())
        this.camera.position.copy(direction.multiplyScalar(-1).add(this.center))
    }

    protected configureLights() {
        // const ambientLight = new AmbientLight("rgb(255,255,255)", 1); // soft white light
        // this.scene.add(ambientLight)
        const light = new DirectionalLight('rgb(255,255,255)', 4)

        const hemisphereLight = new HemisphereLight('rgb(255,238,177)', 'rgb(8,8,32)', 4)

        this.scene.add(hemisphereLight)

        this.scene.add(this.spotLight)
    }

    async addTopography(url: string) {


        const response = await fetch(url, {
            method: 'GET',
            headers: {}
        })
        const verticesAndColors = readShapeTriangles(await response.text(), false)

        const geometry = new BufferGeometry();
        geometry.setAttribute("position", new BufferAttribute(verticesAndColors.vertices, 3))
        geometry.setAttribute("color", new BufferAttribute(verticesAndColors.colors, 3))
        geometry.computeVertexNormals()
        geometry.computeBoundingBox()
        const material = new MeshStandardMaterial({
            side: DoubleSide,
            // vertexColors: false,
            color: new Color("rgb(139,69,19)"),
            // transparent: false,
            // opacity: 1
        })
        const mainMesh = new Mesh(geometry, material)
        this.scene.add(mainMesh)
        return mainMesh
    }


    async addTextureTopography(url: string) {

        // const textureUrl
        //     = "https://storage.googleapis.com/epipremnum-development/template/texture.jpeg"

        // const textureUrl
        //     = "https://storage.googleapis.com/epipremnum-development/template/Imagen_test.jpg"

        const textureUrl
            = "https://storage.googleapis.com/epipremnum-development/template/OpenPit.png"
        const textureLoader = new TextureLoader();
        const texture = textureLoader.load(
            textureUrl
        );
        const response = await fetch(url, {
            method: 'GET',
            headers: {}
        })
        const verticesAndColors = readShapeTriangles(await response.text(), false)

        const geometry = new BufferGeometry();
        const uv = verticesAndColors.mapTexture()


        geometry.setAttribute("position", new BufferAttribute(verticesAndColors.vertices, 3))
        geometry.setAttribute("uv", new BufferAttribute(uv, 2))
        geometry.computeVertexNormals()
        geometry.computeBoundingBox()
        // const material = new MeshStandardMaterial({
        const material = new MeshStandardMaterial({
            map: texture,
            side: DoubleSide,


        })
        const mainMesh = new Mesh(geometry, material)
        this.scene.add(mainMesh)
        return mainMesh
    }
}