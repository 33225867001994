import {useNavigate, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {plantService} from "../../api/plantService";
import {IPlantModel} from "../../api/IPlantModel";
import {
    Avatar,
    Fab, Grid,
    ImageList,
    ImageListItem,
    ImageListItemBar, ListItemAvatar,
    Typography
} from "@mui/material";
import {Strings} from "../../Resources/Strings";
import * as muiImage from "mui-image";
import moment from "moment/moment";
import {IPlantImageModel} from "../../api/IPlantImageModel";
import {plantImageService} from "../../api/planImageService";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import {apiUtils} from "../../api/ApiConfig";
import EditIcon from "@mui/icons-material/Edit";
import ViewInArIcon from "@mui/icons-material/ViewInAr";
import {IPlantShelfWithoutShapeModel} from "../../api/IPlantShelfModel";
import {plantShelfService} from "../../api/plantShelfService";
import {ArrowForward} from "@mui/icons-material";
import {imageStyle, spacing} from "../../Resources/styles";

export const PlantDetailsComponent = () => {

    const navigate = useNavigate()

    const {id} = useParams()
    const [plant, setPlant] = useState<IPlantModel>()
    const [plantImages, setPlantImages] = useState<IPlantImageModel[]>()
    const [shelf, setShelf] = useState<IPlantShelfWithoutShapeModel>()


    useEffect(() => {
        loadDataAsync()
    }, [])


    const loadDataAsync = async () => {
        // Load Plant
        const plantModel = await plantService.readOneAsync(id!)
        setPlant(plantModel)
        // Load Plant Images
        const plantImageModels = await plantImageService.readAsync({
            plantIds: [id!]
        })
        setPlantImages(plantImageModels)

        // Configure Shelf
        setShelf(undefined)
        if (plantModel.storedInCubicles && plantModel.storedInCubicles.length > 0) {
            const shelf = await plantShelfService
                .readNameByCubicleAsync(plantModel.storedInCubicles[0]._id)
            setShelf(shelf)
        }
    }


    return <>

        <Grid container spacing={spacing}>
            <Grid item xs='auto'>
                <Typography variant='h5'>{Strings.PlantDetails}</Typography>
            </Grid>
            <Grid item xs>

            </Grid>

            <Grid item xs='auto'>
                <Fab color='primary' size='small' onClick={event => {
                    navigate(`/plant/edit/${id!}`)
                }}>
                    <EditIcon/>
                </Fab>
            </Grid>
        </Grid>

        {plant && <Grid container>
            <Grid item xs='auto'>
                <muiImage.Image height='250px' alt='thumbnail'
                                {...imageStyle}
                                src={plant.thumbnailPictureUrl}
                                fit='contain'/>
            </Grid>

            <Grid item>
                <List dense disablePadding>
                    <ListItem key='name'>
                        <ListItemText primary={plant.name}
                                      secondary={Strings.Name}/>
                    </ListItem>

                    <ListItem key='id'>
                        <ListItemText primary={plant.id}
                                      secondary={Strings.Identifier}/>
                    </ListItem>

                    <ListItem key='description'>
                        <ListItemText primary={plant.description}
                                      secondary={Strings.Description}/>
                    </ListItem>

                    <ListItem key='status'>
                        <ListItemText primary={plant.status}
                                      secondary={Strings.Status}/>
                    </ListItem>

                    <ListItem key='creation'>
                        <ListItemText primary={apiUtils.mongoIdToDate(plant.id).toLocaleString()}
                                      secondary={Strings.Creation}/>
                    </ListItem>

                    {plant.plantDate && <ListItem disablePadding key='plantDate'>
                        <ListItemText primary={moment(plant.plantDate).toDate().toLocaleString()}
                                      secondary={Strings.PlantDate}/>
                    </ListItem>}

                </List>
            </Grid>

            <Grid item xs={12}>
                <List>
                    <ListItem key='image_header'>
                        <Typography variant='h6'>{`${Strings.Images}`}</Typography>
                    </ListItem>
                    {plantImages && <ListItem key='images'>
                        <ImageList>
                            {plantImages.map(plantImage =>
                                <ImageListItem key={plantImage.id} sx={{borderRadius: '50px'}}>
                                    <muiImage.Image height='250px' alt={plantImage.id}
                                                    {...imageStyle}
                                                    src={plantImage.url}
                                                    />
                                    <ImageListItemBar title={moment(plantImage.pictureDate).toDate().toDateString()}
                                                      subtitle={plantImage.id}
                                                      actionPosition='left'

                                                      position='bottom'>
                                    </ImageListItemBar>
                                </ImageListItem>
                            )}
                        </ImageList>
                    </ListItem>}

                    <ListItem key='cubicles_header'>
                        <Grid container spacing={spacing}>
                            <Grid item xs='auto'>
                                <Typography variant='h6'>{`${Strings.Cubicles} ${shelf?.name}`}</Typography>
                            </Grid>
                            <Grid xs>

                            </Grid>
                            <Grid item xs='auto'>
                                <Fab variant='circular' color='primary' size='small'
                                     onClick={() => navigate(`/plant/shelf/${id!}`)}>
                                    <ArrowForward/>
                                </Fab>
                            </Grid>
                        </Grid>
                    </ListItem>

                    {plant.storedInCubicles.map(storedCubicle =>
                        <ListItem key={storedCubicle._id}>
                            <ListItemAvatar>
                                <Avatar variant='circular' color='primary'>
                                    <ViewInArIcon color='primary'/>
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={`${storedCubicle._id}`}/>
                        </ListItem>)}
                </List>

            </Grid>
        </Grid>}
    </>
}