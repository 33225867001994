import {DrillHoleFilterAbstract} from "./DrillHoleFilterAbstract";
import {IDrillHoleCollarClientModel} from "../IDrillHoleCollarClientModel";
import moment from "moment";

export class DrillHoleFilterByDate extends DrillHoleFilterAbstract {
    private readonly _fromDate?: Date;
    private readonly _toDate?: Date;

    constructor(fromDate?: Date, toDate?: Date) {
        super();
        this._fromDate = fromDate;
        this._toDate = toDate;
    }

    filter(collar: IDrillHoleCollarClientModel): boolean[] {
        let status: boolean[] = []

        let include = false
        if (collar.startDate && collar.endDate && this._fromDate && this._toDate) {
            const collarStartDate = moment(collar.startDate).toDate()
            const collarEndDate = moment(collar.endDate).toDate()
            if (collarStartDate.getTime() >= this._fromDate.getTime() &&
                collarEndDate.getTime() <= this._toDate.getTime()) {
                include = true
            }
        }

        //
        for (let i = 0; i < collar.assay.length; i++) {
            status.push(include)
        }
        return status;
    }
}

