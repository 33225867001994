import {apiUrl, apiUtils} from "./ApiConfig";
import {IPlantShelfModel, IPlantShelfWithoutShapeModel} from "./IPlantShelfModel";

class PlantShelfService {
    async readAsync(id: string) {
        const requestOptions = apiUtils.getBaseRequest('GET')
        let response = await fetch(`${apiUrl}plantShelf/${id}`, requestOptions);
        return await response.json() as IPlantShelfModel;
    }

    async readNamesAsync() {
        const requestOptions = apiUtils.getBaseRequest('GET')
        let response = await fetch(`${apiUrl}plantShelf/names`, requestOptions);
        return await response.json() as IPlantShelfWithoutShapeModel[];
    }

    async readNameByCubicleAsync(cubicleId: string) {
        const requestOptions = apiUtils.getBaseRequest('GET')
        let response = await fetch(`${apiUrl}PlantShelf/byCubicle/${cubicleId}`, requestOptions);
        return await response.json() as IPlantShelfWithoutShapeModel;
    }
}

export const plantShelfService = new PlantShelfService()



