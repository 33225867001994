import {useNavigate, useParams} from "react-router-dom";
import React, {useEffect, useRef, useState} from "react";
import {plantShelfService} from "../../api/plantShelfService";
import {
    Autocomplete,
    Avatar,
    Box,
    Button,
    Grid,
    IconButton,
    ListItemAvatar,
    TextField,
    Typography
} from "@mui/material";
import {spacing} from "../../Resources/styles";
import {Strings} from "../../Resources/Strings";
import {IPlantShelfWithoutShapeModel} from "../../api/IPlantShelfModel";
import {store} from "../../redux/store";
import {setInProgressOffThunk, setInProgressOnThunk} from "../../redux/progress";
import {plantShelfCubicleService} from "../../api/plantShelfCubicleService";
import {plantService} from "../../api/plantService";
import {StorageComponentViewerManager} from "../../logic/Viewer3D/StorageComponentViewerManager";
import {IPlantModel} from "../../api/IPlantModel";
import {IPlantShelfCubicleModel} from "../../api/IPlantShelfCubicleModel";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import DeleteIcon from "@mui/icons-material/Delete";
import ViewInArIcon from "@mui/icons-material/ViewInAr";
import ListItemText from "@mui/material/ListItemText";
import {useConfirm} from "material-ui-confirm";
import {ViewerDataTemplateComponent} from "../dataTemplate/viewer/ViewerDataTemplateComponent";


export const StoragePlantInCubicleComponent = () => {

    const confirm = useConfirm()
    const {plantId} = useParams()
    const navigate = useNavigate()


    const [shelfWithoutShapes, setShelfWithoutShapes] =
        useState<IPlantShelfWithoutShapeModel[]>([])
    const [plant, setPlant] = useState<IPlantModel>()
    const [selectedShelWithoutShape, setSelectedShelWithoutShape] =
        useState<IPlantShelfWithoutShapeModel | undefined>()
    const [manager] = useState<StorageComponentViewerManager>(new StorageComponentViewerManager())

    const [selectedCubicles, setSelectedCubicles] =
        useState<IPlantShelfCubicleModel[]>([])


    const [hoverCubicle, setHoverCubicle] =
        useState<IPlantShelfCubicleModel>()

    const
        isDataLoadedRef = useRef(false)


    useEffect(() => {
        loadInitialData()
    }, [])

    useEffect(() => {
        if (!selectedShelWithoutShape) return;
        loadShelfData()
    }, [selectedShelWithoutShape])


    const assignCubiclesCallback = async () => {
        try {
            await confirm()
            for (let selectedCubicle of selectedCubicles) {
                await plantService.updateAsync({
                    id: plantId!,
                    placeInCubicleId: selectedCubicle.id
                })
            }
            navigate(`/plant/edit/${plantId}`)
        } catch {

        } finally {

        }
    }


    useEffect(() => {
        if (isDataLoadedRef.current)
            return
        if (!manager || !plant)
            return;
        isDataLoadedRef.current = true

        const loadInitialShelf = async () => {
            if (plant.storedInCubicles && plant.storedInCubicles.length > 0) {
                const shelfWithoutShapeModel = await plantShelfService
                    .readNameByCubicleAsync(plant.storedInCubicles[0]._id)
                console.log("AQUIII")
                setSelectedShelWithoutShape(shelfWithoutShapeModel)
            }
        }
        loadInitialShelf()
    }, [manager, selectedShelWithoutShape, plant])
    const loadInitialData = async () => {

    }

    const loadShelfData = async () => {
        if (!manager || !selectedShelWithoutShape)
            throw new Error("Undefined viewer manager")
        await store.dispatch(setInProgressOnThunk())

        setSelectedCubicles([])

        const shelfId = selectedShelWithoutShape.id
        const shelfModel = await plantShelfService.readAsync(shelfId)
        const plantShelfCubicleModels = await plantShelfCubicleService
            .readAsync({shelfIds: [shelfId]})

        const plantModels = (await plantService.readAsync({
            shelfCubicleIds: plantShelfCubicleModels.map(value => value.id)
        }))

        await manager.setItems(shelfModel, plantShelfCubicleModels, plantModels)
        await store.dispatch(setInProgressOffThunk())
    }


    const initViewerCallback = async () => {

        manager.setProps({
            onSelectedCubicle: cubicle => {
                selectedCubicles.push(cubicle)
                setSelectedCubicles([...selectedCubicles])
            },
            onUnselectedCubicle: cubicle => {
                selectedCubicles.splice(selectedCubicles.indexOf(cubicle), 1)
                setSelectedCubicles([...selectedCubicles])
            },
            onMouseOverCubicle: cubicle => {
                setHoverCubicle(cubicle)
            },
            multipleSelectable: true
        })

        const shelfNames = await plantShelfService.readNamesAsync();
        const plant = await plantService.readOneAsync(plantId!)
        setShelfWithoutShapes(shelfNames)
        setPlant(plant)
    }


    useEffect(() => {
        loadInitialData()
    }, [manager])


    return <Grid container spacing={spacing}>
        {plant && <Grid item xs={12}>
            <Typography variant='h5'>
                Colocar planta en cúbiculo {plant.name}
            </Typography>
        </Grid>}

        {shelfWithoutShapes && plant && <>
            {plant.storedInCubicles.length == 0 &&
                <Grid item xs={12}>
                    <Autocomplete disableClearable
                                  onChange={async (event, item) => {
                                      setSelectedShelWithoutShape(item)
                                  }}
                                  getOptionLabel={(option) => option.name}
                                  renderInput={(params) =>
                                      <TextField {...params} label={Strings.Status}/>}
                                  options={shelfWithoutShapes}/>
                </Grid>
            }


            {plant.storedInCubicles.length > 0 && selectedShelWithoutShape &&
                <Grid item xs={12}>
                    <Typography variant='h6'>{selectedShelWithoutShape.name}</Typography>
                </Grid>
            }
        </>}
        <Grid item xs={12}>
            <ViewerDataTemplateComponent manager={manager} viewerInitCallback={initViewerCallback}/>
        </Grid>


        {selectedCubicles && <>
            <Grid item xs={12}>
                <Typography variant='h6'>{Strings.SelectedCubicles}</Typography>
                <List>
                    {selectedCubicles.map(storedCubicle =>
                        <ListItem key={storedCubicle.id}
                                  secondaryAction={
                                      <Box>
                                          <IconButton>
                                              <DeleteIcon color='error'/>
                                          </IconButton>
                                      </Box>
                                  }>
                            <ListItemAvatar>
                                <Avatar variant='circular'>
                                    <ViewInArIcon/>
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={`${storedCubicle.id}`}/>
                        </ListItem>)}
                </List>
            </Grid>
        </>}
        {selectedCubicles.length > 0 && <Grid item xs={12}>
            <Button fullWidth variant='contained'
                    onClick={assignCubiclesCallback}>{Strings.AddCubicles}</Button>
        </Grid>}
    </Grid>
}