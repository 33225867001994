import {RefObject} from "react";
import {fabric} from "fabric";

export const resizePictureInFabricCanvas = (fabricCanvasRef: RefObject<fabric.Canvas | undefined>,
                                            pictureRef: RefObject<fabric.Image | undefined>,
                                            canvasRef: RefObject<HTMLCanvasElement>) => {
    if (!fabricCanvasRef.current || !pictureRef.current || !canvasRef.current)
        return false
    let fabricCanvas = fabricCanvasRef.current!
    let picture = pictureRef.current!
    let canvas = canvasRef.current!

    const bbox = canvas.getBoundingClientRect()


    const delta = bbox.left
    const height = window.innerHeight - bbox.top - delta;
    const width = window.innerWidth - bbox.left - delta;

    fabricCanvas.setHeight(height)
    fabricCanvas.setWidth(width)

    let ratioWidth = width / picture.width!
    let ratioHeight = height / picture.height!

    if (ratioWidth > ratioHeight)
        picture.scale(ratioHeight)
    else picture.scale(ratioWidth)

    let pictureOffsetX = (width - picture.getScaledWidth()) / 2
    let pictureOffsetY = (height - picture.getScaledHeight()) / 2

    picture.set({
        left: pictureOffsetX,
        top: pictureOffsetY,
    })
    return true
}

export const setRoiMaximumSize = (picture: fabric.Image,
                                  roiRect: fabric.Rect,
                                  fabricCanvas: fabric.Canvas) => {


    let pictureWidth = picture.getScaledWidth()
    let pictureHeight = picture.getScaledHeight()

    let roiLength = pictureWidth
    if (pictureHeight < roiLength)
        roiLength = pictureHeight

    // Add ROI
    roiRect.scaleX = 1
    roiRect.scaleY = 1
    roiRect.left = picture.left! + pictureWidth / 2 - roiLength / 2
    roiRect.top = picture.top! + pictureHeight / 2 - roiLength / 2
    roiRect.width = roiLength
    roiRect.height = roiLength

    fabricCanvas.renderAll()
    return true
}


