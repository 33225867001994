import {DrillHoleFilterAbstract} from "./DrillHoleFilterAbstract";
import {IDrillHoleCollarClientModel} from "../IDrillHoleCollarClientModel";

export class DrillHoleFilterByProgress extends DrillHoleFilterAbstract {
    private readonly _minProgressPercentage?: number;
    private readonly _maxProgressPercentage?: number;


    constructor(minProgressPercentage?: number, maxProgressPercentage?: number) {
        super();
        this._minProgressPercentage = minProgressPercentage;
        this._maxProgressPercentage = maxProgressPercentage;
    }

    filter(collar: IDrillHoleCollarClientModel): boolean[] {

        const compliance = collar.compliancePercentage
        let include = false

        if (compliance) {

            if (this._minProgressPercentage && this._maxProgressPercentage) {
                if (compliance >= this._minProgressPercentage && compliance <= this._maxProgressPercentage)
                    include = true
            } else if (this._maxProgressPercentage) {
                if (compliance <= this._maxProgressPercentage)
                    include = true
            } else if (this._minProgressPercentage) {
                if (compliance >= this._minProgressPercentage!)
                    include = true
            }
        }
        return collar.assay.map(value => include)
    }
}