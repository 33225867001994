import React, {FC, useEffect, useState} from "react";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import {Checkbox, Stack, TextField, Typography} from "@mui/material";
import moment, {Moment} from "moment";
import {DrillHoleAssayViewerContainer} from "../../../models/demo/DrillHoleAssayViewerContainer";
import {spacing} from "../../../Resources/styles";
import {DrillHoleFilterAbstract} from "../../../models/demo/filter/DrillHoleFilterAbstract";
import {DrillHoleFilterAllVisible} from "../../../models/demo/filter/DrillHoleFilterAllVisible";
import {DrillHoleFilterByDate} from "../../../models/demo/filter/DrillHoleFilterByDate";

interface IDrillHoleViewerFilterByDateComponentProps {
    container: DrillHoleAssayViewerContainer,
    applyFilter?: (filter: DrillHoleFilterAbstract) => void
}

export const DrillHoleViewerFilterByDateComponent: FC<IDrillHoleViewerFilterByDateComponentProps> = (props) => {

    const [isAvailable, setIsAvailable] = useState<boolean>(false)

    const [fromDate, setFromDate] = useState<Moment>(moment());
    const [toDate, setToDate] = useState<Moment>(moment());

    useEffect(() => {
        const range = props.container.getRange()

        setFromDate(moment(range.fromDate))
        setToDate(moment(range.toDate))

    }, [])


    useEffect(() => {
        if (!isAvailable) {
            if (props.applyFilter)
                props.applyFilter(new DrillHoleFilterAllVisible())
            return
        }
        if (props.applyFilter)
            props.applyFilter(new DrillHoleFilterByDate(fromDate.toDate(), toDate.toDate()))

    }, [fromDate, toDate, isAvailable])


    return <Stack direction='row' spacing={spacing} alignItems='center'>
        <Checkbox checked={isAvailable} onChange={event => {
            setIsAvailable(event.target.checked);
        }}/>

        <Typography  variant='body1'>
            Rango de Fecha
        </Typography>
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
                disabled={!isAvailable}
                label="Desde"
                value={fromDate}
                onChange={(newValue) => {
                    if (newValue)
                        setFromDate(newValue);
                }}
                renderInput={(params) => <TextField {...params} />}
            />
        </LocalizationProvider>

        <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
                disabled={!isAvailable}
                label="Hasta"
                value={toDate}
                onChange={(newValue) => {
                    if (newValue)
                        setToDate(newValue);
                }}
                renderInput={(params) => <TextField {...params} />}
            />
        </LocalizationProvider>
    </Stack>
}



