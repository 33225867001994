import {useState} from "react";
import {QRCode} from "jsqr";
import {useNavigate} from "react-router-dom";
import {ImagePlantQrLocation} from "../../../logic/imagePlantQrLocation";
import {checkEpipremnumQrCode} from "../../../logic/checkQrCode";
import {QrCameraDataTemplateComponent} from "../../dataTemplate/qrCamera/qrCameraDataTemplateComponent";
import Box from "@mui/material/Box";
import {margin, spacing} from "../../../Resources/styles";
import {Button, Grid, Typography} from "@mui/material";
import {Strings} from "../../../Resources/Strings";

export const EpipremnumScanComponent = () => {
    const [isLocated, setIsLocated] = useState<boolean>(false)
    const [qrCode, setQrCode] = useState<QRCode>()
    const [epipremnumId, setEpipremnumId] = useState<string>()
    const navigate = useNavigate()


    const onFoundedQr = (inputLocation: ImagePlantQrLocation, inputQrCode: QRCode) => {
        setQrCode(inputQrCode)
        setIsLocated(true)
    }

    const onValidateQr = async (inputLocation: ImagePlantQrLocation, qrCode: QRCode) => {
        // Check if data
        setEpipremnumId(undefined)
        let epipremnumQrCode = await checkEpipremnumQrCode(qrCode.data)
        if (!epipremnumQrCode.isValid) {
            setEpipremnumId(undefined)
        } else {
            setEpipremnumId(epipremnumQrCode.id)
        }
        return epipremnumQrCode.isValid
    }

    if (!isLocated || !qrCode)
        return <QrCameraDataTemplateComponent onFoundedQr={onFoundedQr}
                                              onValidateQr={onValidateQr}/>

    // Case of Cutting Storage
    return <Box m={margin}>
        <Grid spacing={spacing} container direction="column"
              justifyContent="center"
              alignItems="stretch">

            <Grid item xs={12}>
                <Typography variant='h6'>
                    {Strings.Epipremnum} {epipremnumId}
                </Typography>
            </Grid>

            <Grid item xs={12}>
                <Button variant='contained' onClick={() => {
                    navigate(`/epipremnum/${epipremnumId}`)
                }} color='primary' fullWidth>
                    {Strings.GoToDetails}</Button>
            </Grid>
            <Grid item xs={12}>
                <Button variant='contained' onClick={() => {
                    setIsLocated(false)
                }} color='secondary' fullWidth>
                    {Strings.TakePictureAgain}
                </Button>
            </Grid>
        </Grid>
    </Box>
}