import {useEffect, useRef, useState} from "react";
import {QrCodeDrawing} from "../logic/qrCodeDrawing";

export const ParticleComponent = () => {
    const [qrCoding] = useState<QrCodeDrawing>(new QrCodeDrawing(0, 0))
    const canvasRef = useRef<HTMLCanvasElement>(null)
    const currentTimeRef = useRef(0)

    useAnimationFrame(deltaTime => {

        currentTimeRef.current = currentTimeRef.current + deltaTime
        if (!canvasRef.current)
            return


        let canvas = canvasRef.current
        let context = canvas.getContext('2d')!

        context.clearRect(0, 0, canvas.width, canvas.height)
        qrCoding.setContext(context)
        qrCoding.update(currentTimeRef.current)
    })

    useEffect(() => {
        if (!canvasRef.current)
            return
        let context = canvasRef.current.getContext('2d')!
        qrCoding.setCenter(0, 0)
        qrCoding.setContext(context)
    }, [canvasRef])

    useEffect(() => {
        window.addEventListener('resize', () => setSize());
        window.addEventListener('mousemove', (e) => {
            qrCoding.setCenter(e.x, e.y)
        })
        setSize()
    }, [])

    const setSize = () => {
        if (!canvasRef.current)
            return
        let canvas = canvasRef.current
        canvas.height = window.innerHeight;
        canvas.width = window.innerWidth;
    }

    return <>
        <canvas ref={canvasRef} style={{height: '100vh', width: '100hw'}}></canvas>
    </>
}

export const useAnimationFrame = (callback: (deltaTime: number) => void) => {
    const requestRef = useRef(0);
    const previousTimeRef = useRef(0);

    const animate = (time: number) => {
        if (previousTimeRef.current !== undefined) {
            const deltaTime = time - previousTimeRef.current;
            callback(deltaTime);
        }
        previousTimeRef.current = time;
        requestRef.current = requestAnimationFrame(animate);
    };

    useEffect(() => {
        requestRef.current = requestAnimationFrame(animate);
        return () => cancelAnimationFrame(requestRef.current);
    }, []);
}
