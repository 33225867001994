import {apiUrl, apiUtils} from "./ApiConfig";
import {IPlantShelfModel} from "./IPlantShelfModel";
import {IPlantShelfCubicleModel} from "./IPlantShelfCubicleModel";


interface ReadShelfCubicleFilter {
    ids?: string[]
    shelfIds?: string[]
}

class PlantShelfCubicleService {
    async readAsync(filter?: ReadShelfCubicleFilter) {
        const requestOptions = apiUtils.getBaseRequest('GET')
        let uri = `${apiUrl}plantShelfCubicle?`

        if (filter){
            if (filter.shelfIds){
                for (let shelfId of filter.shelfIds) {
                    uri += `ShelfIds=${shelfId}&`
                }
            }
            if (filter.ids){
                for (let id of filter.ids) {
                    uri += `Ids=${id}&`
                }
            }

        }
        let response = await fetch(uri, requestOptions);
        return await response.json() as IPlantShelfCubicleModel[];
    }

    async readOneAsync(id: string) {
        const requestOptions = apiUtils.getBaseRequest('GET')
        let uri = `${apiUrl}plantShelfCubicle/${id}`
        let response = await fetch(uri, requestOptions);
        return await response.json() as IPlantShelfCubicleModel;
    }
}

export const plantShelfCubicleService = new PlantShelfCubicleService()