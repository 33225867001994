import React, {useEffect, useState} from "react";
import {drillHoleService} from "../../../api/demo/drillHoleService";
import {Button, Divider, Grid, Paper, Stack, Typography} from "@mui/material";
import {TopographyViewerManager} from "../../../logic/Viewer3D/TopographyViewerManager";
import {Color} from "three";
import {DrillHoleAssayViewerContainer} from "../../../models/demo/DrillHoleAssayViewerContainer";
import {ViewerDataTemplateComponent} from "../../dataTemplate/viewer/ViewerDataTemplateComponent";
import {setInProgressOffThunk, setInProgressOnThunk} from "../../../redux/progress";
import {store} from "../../../redux/store";
import {elevation, margin, padding, spacing} from "../../../Resources/styles";
import {DrillHoleViewerFilterByCutComponent} from "./DrillHoleViewerFilterByCutComponent";
import {DrillHoleFilterAbstract} from "../../../models/demo/filter/DrillHoleFilterAbstract";
import {DrillHoleFilterAllVisible} from "../../../models/demo/filter/DrillHoleFilterAllVisible";
import {DrillHoleViewerFilterByDateComponent} from "./DrillHoleViewerFilterByDateComponent";
import {DrillHoleViewerFilterByProgressComponent} from "./DrillHoleViewerFilterByProgressComponent";
import {DrillHoleFilterByProgress} from "../../../models/demo/filter/DrillHoleFilterByProgress";
import {DrillHoleViewerDownloadReportComponent} from "./DrillHoleViewerDownloadReportComponent";

export const DrillHoleViewerComponent = () => {
    const [manager] = useState<TopographyViewerManager>(() => new TopographyViewerManager())

    const [container, setContainer]
        = useState<DrillHoleAssayViewerContainer>()

    const [cutFilter, setCutFilter] =
        useState<DrillHoleFilterAbstract>(() => new DrillHoleFilterAllVisible(true))
    const [dateFilter, setDateFilter] =
        useState<DrillHoleFilterAbstract>(() => new DrillHoleFilterAllVisible(true))
    const [progressFilter, setProgressFilter] =
        useState<DrillHoleFilterAbstract>(() => new DrillHoleFilterAllVisible(true))

    const initCallback = async () => {

        await store.dispatch(setInProgressOnThunk())
        manager.scene.background = new Color(0.98, 0.98, 0.98)
        const drillHoleCollarModels = await drillHoleService.readClientModels()

        const assayViewerContainer =
            new DrillHoleAssayViewerContainer(drillHoleCollarModels)

        setContainer(assayViewerContainer)

        await manager.addTopography
        ("https://storage.googleapis.com/epipremnum-development/template/small_drill_topography.csv")

        for (let line of assayViewerContainer.getLines()) {
            manager.scene.add(line)
        }
        await store.dispatch(setInProgressOffThunk())
        manager.fitCamera()
    }

    useEffect(() => {
        if (!container) return
        container.filter([dateFilter, cutFilter, progressFilter])
    }, [dateFilter, cutFilter, progressFilter])


    return <Grid container spacing={spacing} direction='row'>
        <Grid item xs={12}>
            <Typography variant='h5'>
                Visualizador Sondajes
            </Typography>
        </Grid>

        <Grid item xs={12} md={7}>
            <ViewerDataTemplateComponent manager={manager} viewerInitCallback={initCallback}/>
        </Grid>
        <Grid item xs={12} md={5}>
            <Paper elevation={elevation}>
                {container && <Stack direction={'column'} m={margin} spacing={spacing} divider={<Divider/>} p={padding}>
                    {/* -- Leyes de Cobre -- */}
                    <DrillHoleViewerFilterByCutComponent container={container}
                                                         applyFilter={filter => setCutFilter(filter)}/>

                    {/* -- Leyes de Cobre -- */}
                    <DrillHoleViewerFilterByDateComponent container={container}
                                                          applyFilter={filter => setDateFilter(filter)}/>

                    {/* Filtro Progreso */}
                    <DrillHoleViewerFilterByProgressComponent container={container}
                                                              applyFilter={filter => setProgressFilter(filter)}/>

                    {/*Reporte*/}
                    <DrillHoleViewerDownloadReportComponent container={container}/>
                </Stack>}
            </Paper>
        </Grid>
    </Grid>
}
