import React, {FC, useEffect, useRef, useState} from "react";
import {Box, Button, Checkbox, Grid, IconButton, List, ListItemButton} from "@mui/material";
import {Color} from "three";
import {setInProgressOffThunk, setInProgressOnThunk} from "../../../redux/progress";
import {store} from "../../../redux/store";
import {TopographyViewerManager} from "../../../logic/Viewer3D/TopographyViewerManager";
import {ViewerDataTemplateComponent} from "../../dataTemplate/viewer/ViewerDataTemplateComponent";


interface ITopographyViewerComponentProps {

    topographyUrl: string
}

export const TopographyViewerComponent: FC<ITopographyViewerComponentProps> = (props) => {
    const mountRef = useRef(null)
    const [manager, setManager] =
        useState<TopographyViewerManager>(new TopographyViewerManager())

    const initViewerFunction = async () => {
        await store.dispatch(setInProgressOnThunk())
        manager.scene.background = new Color(0.98, 0.98, 0.98)
        await manager.addTopography(props.topographyUrl)

        await store.dispatch(setInProgressOffThunk())
        manager.fitCamera()
    }


    return <>
        <ViewerDataTemplateComponent manager={manager} viewerInitCallback={initViewerFunction}/>

        <Button onClick={() => {
            manager.fitCamera()
        }}>Fit</Button>
    </>
}