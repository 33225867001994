import {Avatar, IconButton, Menu, MenuItem, Typography} from "@mui/material";
import PersonIcon from '@mui/icons-material/Person';
import {useAuth0} from "@auth0/auth0-react";
import {useEffect, useState} from "react";
import {Strings} from "../../../Resources/Strings";
import {useNavigate} from "react-router-dom";


export const AuthenticationComponent = () => {

    const navigate = useNavigate()
    const {isAuthenticated, logout, loginWithRedirect, user, } = useAuth0();
    const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);



    const closeUserMenuCallback = () => {
        setAnchorElUser(null);
    };

    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };


    const onLoginCallback = async () => {
        await loginWithRedirect({})
    }

    const onLogoutCallback = async () => {
        await logout()
        closeUserMenuCallback()
    }

    return <>
        {!isAuthenticated && <IconButton color='secondary' onClick={onLoginCallback}>
            <PersonIcon/>
        </IconButton>}

        {isAuthenticated && <>
            <IconButton onClick={handleOpenUserMenu}>
                <Avatar color='secondary'
                        src={user?.picture} alt='Avatar picture'/>
            </IconButton>

            <Menu
                sx={{mt: '45px'}}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={closeUserMenuCallback}>

                <MenuItem key="userDetails" onClick={() => {
                    navigate("userdetails")
                    closeUserMenuCallback()
                }}>
                    <Typography textAlign="center">{Strings.UserDetails}</Typography>
                </MenuItem>
                <MenuItem key="logout" onClick={onLogoutCallback}>
                    <Typography textAlign="center">{Strings.Logout}</Typography>
                </MenuItem>
            </Menu>
        </>
        }
    </>
}