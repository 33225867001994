import {store} from "../../redux/store";
import {setInProgressOffThunk, setInProgressOnThunk} from "../../redux/progress";
import MaterialReactTable, {MRT_ColumnDef} from "material-react-table";
import {IPlantCollectionRow} from "./IPlantCollectionRow";
import {Strings} from "../../Resources/Strings";
import {CircularProgress, Grid, IconButton, Tooltip, Typography} from "@mui/material";
import {margin, spacing} from "../../Resources/styles";
import Image from "mui-image";
import {Link, useNavigate} from "react-router-dom";
import Box from "@mui/material/Box";
import {MRT_Localization_ES} from "material-react-table/locales/es";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import EditIcon from "@mui/icons-material/Edit";
import {useEffect, useState} from "react";
import {plantService} from "../../api/plantService";
import {apiUtils} from "../../api/ApiConfig";


const columns: MRT_ColumnDef<IPlantCollectionRow>[] = [{
    header: `${Strings.Name}`,
    Cell: ({row, cell}) => {
        return <Grid container spacing={spacing} alignItems='center' alignContent='center'>
            <Grid xs='auto' item>
                <Image alt="thumbnail" src={row.original.thumbnail} height={40} width={40} easing='ease-in'
                       style={{borderRadius: "50%"}} showLoading={<CircularProgress/>} fit='contain'/>
            </Grid>
            <Grid xs='auto' item>
                <Typography>{row.original.name}</Typography>
            </Grid>
        </Grid>
    },
    accessorKey: 'name',
    maxSize: 180,
}, {
    header: `${Strings.Identifier}`,
    accessorKey: 'id',
    Cell: ({cell}) => <Link to={`/plant/${cell.getValue<string>()}`}>
        <Typography>{cell.getValue<string>()}</Typography></Link>,
    size: 10,
    maxSize: 30,
},{
    header: `${Strings.Status}`,
    accessorKey: 'status',
    size: 10,
    maxSize: 30,
}]

export const PlantCollectionComponent = () => {
    const [rowData, setRowData] = useState<IPlantCollectionRow[]>([])
    const navigate = useNavigate()

    useEffect(() => {
        loadDataAsync()
    }, [])
    const loadDataAsync = async () => {
        try {
            await store.dispatch(setInProgressOnThunk())
            const plants = await plantService.readAsync()
            let plantRows = plants.map(value => {
                const plantCollectionRow: IPlantCollectionRow = {
                    description: value.description,
                    id: value.id,
                    status: value.status,
                    name: value.name,
                    thumbnail: value.thumbnailPictureUrl
                }
                return plantCollectionRow
            })
            setRowData([...plantRows])
        } catch (e) {
            console.error(e)
        } finally {
            await store.dispatch(setInProgressOffThunk())
        }
    }


    return <Box m={margin}>
        <Grid container spacing={spacing}>
            <Grid item xs={12}>
                <MaterialReactTable
                    renderTopToolbarCustomActions={() => (
                        <Box m={margin}>
                            <Typography fontWeight='bold'>{Strings.Plants}</Typography>
                        </Box>
                    )}
                    displayColumnDefOptions={{
                        'mrt-row-actions': {
                            muiTableHeadCellProps: {
                                align: 'left',
                            },
                            size: 10
                        },
                    }}
                    columns={columns}
                    data={rowData}
                    enableColumnOrdering
                    enableColumnActions
                    enableRowActions
                    localization={MRT_Localization_ES}

                    renderDetailPanel={({row}) => (
                        <Grid spacing={spacing} container direction='row' alignItems='center'>
                            <Grid xs='auto' item>
                                <Image alt="thumbnail" src={row.original.thumbnail} height={150} width={150}
                                       easing='ease-in' duration={1}
                                       style={{borderRadius: "5%"}} showLoading={<CircularProgress/>} fit='contain'/>
                            </Grid>

                            <Grid item xs>
                                <Typography variant="h4">{row.original.name}</Typography>
                                <Typography
                                    variant="h4">{apiUtils.mongoIdToDate(row.original.id)
                                    .toLocaleString('es-ES')}</Typography>
                            </Grid>
                        </Grid>)}

                    renderRowActions={({row}) => (
                        <Box>
                            <Tooltip arrow placement="left" title={Strings.AddEpipremnumPhotographyRecord}>
                                <IconButton onClick={() => {
                                    navigate(`/plantImage/create/${row.getValue('id')}`)
                                }} size='small' color='primary'>
                                    <CameraAltIcon/>
                                </IconButton>
                            </Tooltip>
                            <Tooltip arrow placement="right" title={Strings.Edit}>
                                <IconButton color="primary" size='small' onClick={() => {
                                    navigate(`/plant/edit/${row.getValue('id')}`)
                                }}>
                                    <EditIcon/>
                                </IconButton>
                            </Tooltip>
                        </Box>
                    )}
                />
            </Grid>
        </Grid>
    </Box>
}