import {BufferAttribute, BufferGeometry, Color, DoubleSide, Mesh, MeshStandardMaterial} from "three";
import {IPhaseModel} from "../../models/demo/IPhaseModel";
import {phaseService} from "../../api/demo/phaseService";
import {randFloat} from "three/src/math/MathUtils";
import {Base3dViewerManager} from "../../components/dataTemplate/viewer/Base3dViewnerManager";

export class PhaseViewerManager extends Base3dViewerManager {

    private _phaseViewerItems: ViewerManagerPhaseModel[] = []

    constructor() {
        super();
    }

    async addItem(phaseModel: IPhaseModel) {

        const r = randFloat(0, 1)
        const g = randFloat(0, 1)
        const b = randFloat(0, 1)
        let verticesAndColors = await phaseService.readContent(phaseModel)

        const geometry = new BufferGeometry();
        geometry.setAttribute("position", new BufferAttribute(verticesAndColors.vertices, 3))
        geometry.setAttribute("color", new BufferAttribute(verticesAndColors.colors, 3))
        geometry.computeVertexNormals()
        geometry.computeBoundingBox()
        const material = new MeshStandardMaterial({
            side: DoubleSide,
            vertexColors: false,
            color: new Color(r, g, b),
            transparent: true,
            opacity: 1
        })
        const mainMesh = new Mesh(geometry, material)
        this.scene.add(mainMesh)

        let managerPhaseModel = new ViewerManagerPhaseModel(phaseModel, mainMesh)
        this._phaseViewerItems.push(managerPhaseModel)
    }

    setVisible(numbers: number[]) {

        let phases = this._phaseViewerItems
            .filter(value => value.type === "phase")
        for (const phase of phases) {
            phase.setVisible(false)
        }

        for (const number of numbers) {
            let item =
                this._phaseViewerItems.find(value => value.type === "phase" &&
                    value.number === number)
            item?.setVisible(true)
        }
    }

    getPhaseNumbers() {

        const phaseNumbers = this._phaseViewerItems
            .filter(value => value.type === "phase")
            .map(value => value.number)
        return phaseNumbers
    }

    setTopographyVisible(status: boolean) {
        let item =
            this._phaseViewerItems.find(value => value.type === "topography")

        if (item) {
            item.setVisible(status)
        }
    }
}


export class ViewerManagerPhaseModel {

    public phaseModel: IPhaseModel
    public mesh: Mesh
    public type: "phase" | "topography" | "none"
    public number: number

    constructor(phaseModel: IPhaseModel, mesh: Mesh) {
        this.phaseModel = phaseModel
        this.mesh = mesh;

        this.type = "none"
        this.number = -1

        switch (phaseModel.metadata["type"]) {
            case "phase":
                this.type = "phase"
                break
            case "topography":
                this.type = "topography"
                break
            default:
                this.type = "none"
                break
        }

        if (phaseModel.metadata["number"]) {
            this.number = +phaseModel.metadata["number"]
        }

        const color = phaseModel.metadata["color"];

        this.setColor(new Color(color))
        if (this.type === "phase") {
            this.mesh.visible = false
        } else if (this.type === "topography") {
            this.mesh.visible = true
        }
    }

    setVisible(status: boolean) {
        this.mesh.visible = status
    }

    setColor(color: Color) {
        const material = (this.mesh.material as MeshStandardMaterial)
        material.color = color
    }
}