import {Box, Button, Fab, Grid, ImageList, ImageListItem, Tooltip, Typography} from "@mui/material";
import {Link, useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {epipremnumRawPictureService} from "../../../api/epipremnumRawPictureService";
import {setInProgressOffThunk, setInProgressOnThunk} from "../../../redux/progress";
import {store} from "../../../redux/store";
import {IEpipremnumRawPictureOutput} from "../../../api/IEpipremnumRawPictureOutput";
import {Strings} from "../../../Resources/Strings";
import {margin, spacing, thumbnailPixels} from "../../../Resources/styles";
import moment from "moment";
import DeleteIcon from '@mui/icons-material/Delete';
import {useConfirm} from "material-ui-confirm";
import {Image} from "mui-image";
import {epipremnumService} from "../../../api/epipremnumService";
import Resizer from "react-image-file-resizer";
import {useSnackbar} from "notistack";
import GrassIcon from '@mui/icons-material/Grass';
import {RawPictureTypeConstants} from "../../../api/rawPictureTypeConstants";
import {IEpipremnumRelatedPictures} from "../../../api/IEpipremnumRelatedPictures";

export const EpipremnumRawPictureDetailsComponent = () => {
    let {id} = useParams()
    const navigate = useNavigate()
    const confirm = useConfirm()
    const snackbar = useSnackbar()

    const [epipremnumRawPicture, setEpipremnumRawPicture] = useState<IEpipremnumRawPictureOutput>()
    const [relatedPictures, setRelatedPictures] = useState<IEpipremnumRelatedPictures>({
        alphaPicture: undefined,
        hasAlphaPicture: false,
        hasCommercialPicture: false,
        id: "",
        commercialPicture: undefined
    })

    useEffect(() => {
        loadAsync()
    }, [id])

    const loadAsync = async () => {
        try {
            if (!id) return
            store.dispatch(setInProgressOnThunk())
            let item = await epipremnumRawPictureService.readAsync(id);
            let epipremnumRelatedPictures = await epipremnumRawPictureService.getRelatedPictures(id)
            setEpipremnumRawPicture(item)
            setRelatedPictures(epipremnumRelatedPictures)
        } catch (e) {
            console.error(e)
        } finally {
            store.dispatch(setInProgressOffThunk())
        }
    }

    const deleteImageCallback = async () => {
        try {
            await confirm()
            try {
                store.dispatch(setInProgressOnThunk())
                let model = await epipremnumRawPictureService.deleteAsync(id!)
                navigate(`/epipremnum/${model.epipremnum._id}`)
            } catch (e) {
                console.error(e)
            } finally {
                store.dispatch(setInProgressOffThunk())
            }
        } catch {

        }
    }

    const getDimensionsCallback = (rawPicture: IEpipremnumRawPictureOutput) => {
        let widthPixels = rawPicture.width
        let heightPixels = rawPicture.height

        return `${widthPixels}x${heightPixels} px`
    }

    const processAlphaCallback = async () => {
        try {
            await store.dispatch(setInProgressOnThunk())
            let epipremnumRawPicture = await epipremnumRawPictureService.createAlpha(id!, moment())
            navigate(`/epipremnumRawPicture/${epipremnumRawPicture.id}`)
        } catch (e) {
            console.error(e)
        } finally {
            await store.dispatch(setInProgressOffThunk())
        }
    }


    const processCommercialPicture = async () => {
        navigate(`/epipremnumRawPicture/create_commercial_picture/${epipremnumRawPicture!.id}`)
    }

    const setThumbnailCallback = async () => {
        try {
            await confirm()
            await store.dispatch(setInProgressOnThunk())
            epipremnumRawPictureService.readContent(epipremnumRawPicture?.id!).then(async text => {
                const base64Response = await fetch(text);
                const blob = await base64Response.blob()
                Resizer.imageFileResizer(
                    blob,
                    thumbnailPixels,
                    thumbnailPixels,
                    'png',
                    100,
                    0,
                    async value => {
                        let item = await epipremnumService.setThumbnail(epipremnumRawPicture!.epipremnumId, value as string)
                        await store.dispatch(setInProgressOffThunk())
                        snackbar.enqueueSnackbar(`${Strings.UpdatedThumbnail} ${item.thumbnail}`)
                    }, // Is the callBack function of the resized new image URI.
                    "base64"// Is the output type of the resized new image.
                );
            })
        } catch {

        }
    }

    return <Box m={margin}>
        <Typography variant='h6' fontWeight='bold'>
            {Strings.RawPicture} {id}
        </Typography>


        {epipremnumRawPicture &&
            <Grid spacing={spacing} container>
                <Grid item xs='auto'>
                    <Typography fontWeight='bold'>
                        {Strings.Epipremnum}
                    </Typography>
                    <Link to={`/epipremnum/${epipremnumRawPicture.epipremnumId}`}>
                        <Typography>
                            {epipremnumRawPicture.epipremnumId}
                        </Typography>
                    </Link>
                </Grid>


                <Grid item xs='auto'>
                    <Typography fontWeight='bold'>
                        {Strings.PictureDate}
                    </Typography>
                    <Typography>
                        {moment(epipremnumRawPicture.pictureDate).toDate().toLocaleDateString()}
                    </Typography>
                </Grid>

                <Grid item xs>
                    <Typography fontWeight='bold'>
                        {Strings.Description}
                    </Typography>
                    <Typography>
                        {epipremnumRawPicture.description}
                    </Typography>
                </Grid>

                <Grid item xs='auto'>
                    <Typography fontWeight='bold'>
                        {Strings.PictureType}
                    </Typography>
                    <Typography>
                        {epipremnumRawPicture.pictureType}
                    </Typography>
                </Grid>


                <Grid item xs='auto'>
                    <Typography fontWeight='bold'>
                        {Strings.WidthAndHeight}
                    </Typography>
                    <Typography>
                        {getDimensionsCallback(epipremnumRawPicture)}
                    </Typography>
                </Grid>

                <Grid item xs='auto'>
                    <Tooltip title={Strings.Thumbnail}>
                        <Fab onClick={setThumbnailCallback} color='primary' size='medium'>
                            <GrassIcon/>
                        </Fab>
                    </Tooltip>
                </Grid>

                <Grid item xs='auto'>
                    <Fab variant='circular' onClick={deleteImageCallback}
                         component="label" size='medium' color='error'>
                        <DeleteIcon/>
                    </Fab>
                </Grid>


                {epipremnumRawPicture.pictureType == RawPictureTypeConstants.RawPicture &&
                    !relatedPictures.hasAlphaPicture &&
                    <Grid item xs='auto'>
                        <Button variant='contained' onClick={processAlphaCallback}>
                            {Strings.ProcessAlpha}
                        </Button>
                    </Grid>}

                {epipremnumRawPicture.pictureType == RawPictureTypeConstants.AlphaChannel &&
                    !relatedPictures.hasCommercialPicture &&
                    <Grid item xs='auto'>
                        <Button variant='contained' onClick={processCommercialPicture}>
                            {Strings.CreateCommercialPicture}
                        </Button>
                    </Grid>}


                <Grid xs={12} item>

                    <img id="imageId" alt='epipremnum_image' src={epipremnumRawPicture.sharableLink} hidden/>

                    <ImageList>
                        <ImageListItem>
                            <Image alt='epipremnum_image' src={epipremnumRawPicture.sharableLink}
                                   duration={1} fit='contain'/>
                        </ImageListItem>
                    </ImageList>
                </Grid>
            </Grid>


        }
    </Box>
}



