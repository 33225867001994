import {Point} from "jsqr/dist/locator";
import {Vector2} from "three";
import {ImagePlantQrLocation} from "./imagePlantQrLocation";

export class VideoPlantQrLocation {
    leftCorner: Point;
    rightCorner: Point;
    bottomRightAlignment?: Point | null

    constructor(leftCorner: Point, rightCorner: Point, bottomRightAlignment?: Point | null) {
        this.leftCorner = leftCorner
        this.rightCorner = rightCorner
        this.bottomRightAlignment = bottomRightAlignment
    }

    toWorldPoint(imagePoint: Vector2, imageWidth: number, imageHeight: number, width: number, height: number): Vector2 {
        let videoAspectRatio = imageWidth / imageHeight
        let pageAspectRatio = width / height
        let factor = 0;
        let offsetX = 0
        let offsetY = 0
        let pictureHeight = 0
        let pictureWidth = 0

        if (pageAspectRatio > videoAspectRatio) {
            pictureHeight = height;
            pictureWidth = height * videoAspectRatio;
            offsetX = (width - pictureWidth) / 2
        } else {
            pictureWidth = width;
            pictureHeight = width / videoAspectRatio;
            offsetY = (height - pictureHeight) / 2
        }

        factor = pictureHeight / imageHeight

        let x = offsetX + imagePoint.x * factor
        let y = offsetY + imagePoint.y * factor

        return new Vector2(x, y)
    }

    toImageQrLocation(videoWidth: number, videoHeight: number, width: number, height: number): ImagePlantQrLocation {
        let angle = this.getAngleRadians()

        let leftCorner = new Vector2(this.leftCorner.x, this.leftCorner.y)
        let rightCorner = new Vector2(this.rightCorner.x, this.rightCorner.y)


        let worldLeftCorner = this.toWorldPoint(leftCorner, videoWidth, videoHeight, width, height)

        let worldRightCorner = this.toWorldPoint(rightCorner, videoWidth, videoHeight, width, height)


        let center = new Vector2(worldLeftCorner.x, worldLeftCorner.y)
        let worldOrthogonalLeftCorner = worldLeftCorner.rotateAround(center, -angle)
        let worldOrthogonalRightCorner = worldRightCorner.rotateAround(center, -angle)


        let videoAlignment: Vector2 | undefined = undefined
        let imageAlignment: Vector2 | undefined = undefined

        if (this.bottomRightAlignment) {
            videoAlignment = new Vector2(this.bottomRightAlignment?.x, this.bottomRightAlignment?.y)
            imageAlignment = this.toWorldPoint(videoAlignment, videoWidth, videoHeight, width, height)
        }

        return new ImagePlantQrLocation(worldLeftCorner, worldRightCorner, worldOrthogonalLeftCorner,
            worldOrthogonalRightCorner, angle, leftCorner, rightCorner, imageAlignment, videoAlignment)
    }

    getAngleRadians(): number {
        if (!this.bottomRightAlignment) return 0
        let vector = new Vector2(this.bottomRightAlignment.x, this.bottomRightAlignment.y).sub(new Vector2(this.leftCorner.x, this.leftCorner.y))
        return vector.angle() - Math.PI / 4;
    }
}

