import {FC, useState} from "react";
import {
    Alert,
    Box,
    Button,
    Fab,
    Grid, ImageList, ImageListItem, ImageListItemBar, TextField,
    Typography
} from "@mui/material";
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import {IRoiInImageData} from "../roiInImageEditor/IRoiInImageData";
import {RoiInImageDataTemplateComponent} from "../roiInImageEditor/roiInImageDataTemplateComponent";
import {Strings} from "../../../Resources/Strings";
import {margin, spacing} from "../../../Resources/styles";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import moment, {Moment} from "moment/moment";
import {SnackbarKey, useSnackbar} from "notistack";
import LoadingButton from "@mui/lab/LoadingButton";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import * as muiImage from 'mui-image';
import Resizer from "react-image-file-resizer";


interface RawPictureDataTemplateComponentProps {
    onUndoPicture: (id: string) => Promise<void>,
    onPictureCreate: (roiPicture: string, imageWidth: number, imageHeight: number, description: string, pictureDate: moment.Moment) => Promise<string>,
    label: string
}

export const RawPictureDataTemplateComponent: FC<RawPictureDataTemplateComponentProps> = (props) => {

    const {enqueueSnackbar, closeSnackbar} = useSnackbar()

    const [initialPicture, setInitialPicture] = useState<string>()
    const [roiPicture, setRoiPicture] = useState<string>()
    const [pictureDate, setPictureDate] = useState<Moment>(moment());
    const [description, setDescription] = useState<string>("");
    const [isCreating, setIsCreating] = useState<boolean>(false)


    const inputImageCameraPictureCallback = (event: any) => {
        let htmlInputElement: HTMLInputElement = event.target
        if (htmlInputElement.files == null) return;

        let reader = new FileReader();
        reader.readAsDataURL(htmlInputElement.files[0]);

        reader.onload = function () {
            setInitialPicture(reader.result as string)
        };
        // TODO: REsize the image
        // Resizer.imageFileResizer(
        //     htmlInputElement.files[0], // Is the file of the image which will resized.
        //     1024, // Is the maxWidth of the resized new image.
        //     1024, // Is the maxHeight of the resized new image.
        //     'png', // Is the compressFormat of the resized new image.
        //     100, // Is the quality of the resized new image.
        //     0, // Is the degree of clockwise rotation to apply to uploaded image.
        //     value => {
        //         setInitialPicture(value as string)
        //     }, // Is the callBack function of the resized new image URI.
        //     "base64"// Is the output type of the resized new image.
        // );
    }

    const undoPictureCallback = async (key: SnackbarKey, pictureId: string) => {
        try {
            closeSnackbar(key)
            await props.onUndoPicture(pictureId)
            enqueueSnackbar(`${Strings.Deleted}`, {
                content: (key, message) => {
                    return <div key={key}>
                        <Alert severity='warning' onClose={() => closeSnackbar(key)}>{message}</Alert>
                    </div>
                }
            })
        } catch (e) {
            console.error(e)
        }
    }


    const onRoiImageCallback = (roiInImageData: IRoiInImageData) => {
        setRoiPicture(roiInImageData.roiImageUrl)
    }

    const onRoiCancel = () => {
        setInitialPicture(undefined)
    }


    const onDescriptionChanged = (event: any | HTMLInputElement) => {
        setDescription(event.target.value)
    }

    const createRecordImageCallback = async () => {

        let image = new Image();
        image.onload = async function () {
            try {
                setIsCreating(true)
                let imageWidth = image.width
                let imageHeight = image.height
                let id = await props.onPictureCreate(roiPicture!, imageWidth, imageHeight, description, pictureDate)

                enqueueSnackbar(`${Strings.CreatedPicture}`, {
                    content: (key, message) => {
                        return <div key={key}>
                            <Alert severity='success' action={<>
                                <Grid container spacing={spacing}>
                                    <Grid item xs='auto'>
                                        <Button color='warning' size='small' variant='outlined'
                                                onClick={() => undoPictureCallback(key, id)}>
                                            {Strings.Undo}
                                        </Button>
                                    </Grid>
                                </Grid>

                            </>}>{message}</Alert>
                        </div>
                    }
                })
            } catch (e) {
                console.error(e)
            } finally {
                setIsCreating(false)
            }
        };
        image.src = roiPicture!;
    }

    if (!initialPicture) {
        return <Box m={margin}>
            <Grid container spacing={spacing} alignItems='center'>
                <Grid item xs={12}>
                    <Typography variant='h6' fontWeight='bold'>
                        {props.label}
                    </Typography>
                </Grid>

                <Grid item xs='auto'>
                    <Fab variant='circular'
                         component="label" size='large' color='primary'>
                        <input
                            type="file"
                            accept="image/*"
                            onChange={inputImageCameraPictureCallback}
                            hidden
                            capture="environment"
                        />
                        <CameraAltIcon/>
                    </Fab>
                </Grid>

                <Grid item xs='auto'>
                    <Typography>{Strings.TakePicture}</Typography>
                </Grid>
            </Grid>
        </Box>
    }

    if (initialPicture && !roiPicture) {
        return <RoiInImageDataTemplateComponent imageUrl={initialPicture!} onRoiImage={onRoiImageCallback}
                                                onCancel={onRoiCancel}/>
    }

    if (roiPicture && initialPicture) {
        return <Box m={margin}>

            <Grid container spacing={spacing} alignItems='center'>
                <Grid item xs={12}>
                    <Typography variant='h6' fontWeight='bold'>
                        {props.label}
                    </Typography>
                </Grid>


                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label={Strings.Description}
                        value={description} onChange={onDescriptionChanged}
                        multiline
                        minRows={4}
                        maxRows={10}
                        variant="outlined"
                    />
                </Grid>

                <Grid item xs='auto'>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DatePicker
                            label={Strings.PictureDate}
                            value={pictureDate}
                            onChange={(newValue) => {
                                if (newValue)
                                    setPictureDate(newValue);
                            }}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </LocalizationProvider>
                </Grid>

                <Grid item xs>
                    <LoadingButton startIcon={<AddCircleIcon/>} fullWidth loading={isCreating}
                                   onClick={createRecordImageCallback}
                                   variant='contained'>
                        {Strings.Create}
                    </LoadingButton>
                </Grid>

                <Grid item xs={12}>
                    <ImageList>
                        <ImageListItem>
                            <muiImage.Image alt='initial' src={initialPicture} fit='contain'/>
                            <ImageListItemBar position="bottom" title={Strings.Original}/>
                        </ImageListItem>
                        <ImageListItem>
                            <muiImage.Image alt='roi' src={roiPicture!} fit='contain'/>
                            <ImageListItemBar position="bottom" title={Strings.Cropped}/>
                        </ImageListItem>
                    </ImageList>
                </Grid>
            </Grid>
        </Box>
    }
    return <>
    </>
}