import React, {FC, useEffect, useRef, useState} from "react";
import {Box, Button} from "@mui/material";
import {Base3dViewerManager} from "./Base3dViewnerManager";

interface ViewerDataTemplateComponentProps {
    manager: Base3dViewerManager,
    viewerInitCallback?: () => void
}

export const ViewerDataTemplateComponent: FC<ViewerDataTemplateComponentProps> = (props) => {

    const [isMounted, setIsMounted] = useState<boolean>(false)


    useEffect(() => {
        if (isMounted)
            return
        // TODO - Dynamic ID
        const appElement = document.getElementById("app")!
        const cubeElement = document.getElementById("cube-scene")!
        props.manager._init(appElement, cubeElement)

        if (props.viewerInitCallback)
            props.viewerInitCallback()


        setIsMounted(true)

        return () => {

            props.manager.renderer.forceContextLoss()
            props.manager.renderer.dispose()
            props.manager.dispose()
            appElement.remove()
            cubeElement.remove()
        }
    }, [])

    return <>
        <Box component="div" id="app" sx={{height: {xs: '200px', md: '400px'}, border: 1, m: 2}}/>
        <div id="cube-scene" style={{position: "absolute"}}/>
    </>
}