import {Box3, BufferAttribute, BufferGeometry, Vector3} from "three";

export function getSize(bbox: Box3) {
    const x = bbox.max.x - bbox.min.x;
    const y = bbox.max.y - bbox.min.y;
    const z = bbox.max.z - bbox.min.z;

    return new Vector3(x, y, z)
}

export function getMiddle(bbox: Box3) {
    const size = getSize(bbox)
    const min = bbox.min
    return new Vector3(min.x + size.x / 2, min.y + size.y / 2, min.z + size.z / 2)
}


export function getDiagonal(bbox: Box3) {

    const diagonal = getSize(bbox)

    const squaredDistance = Math.pow(diagonal.x, 2) +
        Math.pow(diagonal.x, 2) + Math.pow(diagonal.x, 2)

    return Math.sqrt(squaredDistance)
}

export function readShapeTriangles(textTriangles: string, invert_z: boolean = false) {


    const splits = textTriangles.split('\n')

    const colorsList: number[] = [];
    const verticesList: number[] = [];
    for (let split of splits) {
        let triangleSpecs = split.split(',')

        let x0 = +triangleSpecs[0]
        let y0 = +triangleSpecs[1]
        let z0 = +triangleSpecs[2]

        let x1 = +triangleSpecs[3]
        let y1 = +triangleSpecs[4]
        let z1 = +triangleSpecs[5]

        let x2 = +triangleSpecs[6]
        let y2 = +triangleSpecs[7]
        let z2 = +triangleSpecs[8]


        let r = Math.max(0.0, Math.min(+triangleSpecs[9] / 255.0, 1.0));
        let g = Math.max(0.0, Math.min(+triangleSpecs[10] / 255.0, 1.0));
        let b = Math.max(0.0, Math.min(+triangleSpecs[11] / 255.0, 1.0));


        r = 0.667
        g = 0.333
        b = 0

        if (invert_z) {
            verticesList.push(x0)
            verticesList.push(z0)
            verticesList.push(y0)

            verticesList.push(x1)
            verticesList.push(z1)
            verticesList.push(y1)

            verticesList.push(x2)
            verticesList.push(z2)
            verticesList.push(y2)

        } else {
            verticesList.push(x0)
            verticesList.push(y0)
            verticesList.push(z0)
            verticesList.push(x1)
            verticesList.push(y1)
            verticesList.push(z1)
            verticesList.push(x2)
            verticesList.push(y2)
            verticesList.push(z2)
        }


        colorsList.push(r)
        colorsList.push(g)
        colorsList.push(b)
        colorsList.push(r)
        colorsList.push(g)
        colorsList.push(b)
        colorsList.push(r)
        colorsList.push(g)
        colorsList.push(b)
    }
    return new VerticesAndColors(new Float32Array(verticesList), new Float32Array(colorsList))
}

export class VerticesAndColors {
    vertices: Float32Array
    colors: Float32Array


    constructor(vertices: Float32Array, colors: Float32Array) {

        this.vertices = vertices
        this.colors = colors
    }


    public getBoundingBox() {
        const bufferGeometry = new BufferGeometry()
        bufferGeometry.setAttribute("position", new BufferAttribute(this.vertices, 3))
        bufferGeometry.computeVertexNormals()
        bufferGeometry.computeBoundingBox()
        return bufferGeometry.boundingBox!
    }

    public mapTexture() {
        const box = this.getBoundingBox()

        const minX = box.min.x
        const minY = box.min.y

        const maxX = box.max.x
        const maxY = box.max.y

        const deltaX = maxX - minX
        const deltaY = maxY - minY


        const uv: number[] = []
        for (let i = 0; i < this.vertices.length; i += 3) {

            const x = this.vertices[i + 0]
            const y = this.vertices[i + 1]

            const u = (x - minX) / deltaX
            const v = (y - minY) / deltaY

            uv.push(u)
            uv.push(v)
        }

        return new Float32Array(uv)
    }

}