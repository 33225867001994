import MaterialReactTable, {MRT_ColumnDef} from "material-react-table";
import moment from "moment";
import {IDrillHoleTableRow} from "./IDrillHoleTableRow";
import {FC, useEffect, useState} from "react";
import Box from "@mui/material/Box";
import {margin, spacing} from "../../../Resources/styles";
import {Grid, Typography} from "@mui/material";
import {MRT_Localization_ES} from "material-react-table/locales/es";
import {IDrillHoleCollarModel} from "../../../models/demo/IDrillHoleCollarModel";

const columns: MRT_ColumnDef<IDrillHoleTableRow>[] = [
    {
        header: `Id`,
        accessorFn: (row: IDrillHoleTableRow) => {
            return +row.dhId.split('_')[1]
        },
        accessorKey: 'dhId',
        maxSize: 180,
    }, {
        header: `Inicio`,
        accessorFn: (row: IDrillHoleTableRow) => {
            if (row.startDate) return moment(row.startDate).toDate()
            else return null
        },
        id: 'startDate',
        Cell: ({cell}) => cell.getValue<Date>()?.toLocaleDateString('es-ES'),
        sortingFn: 'datetime',
        size: 10,
        maxSize: 30,
    }, {
        header: `termino`,
        accessorFn: (row: IDrillHoleTableRow) => {
            if (row.endDate) return moment(row.endDate).toDate()
            else return null
        },
        id: 'endDate',
        Cell: ({cell}) => cell.getValue<Date>()?.toLocaleDateString('es-ES'),
        sortingFn: 'datetime',
        size: 10,
        maxSize: 30,
    }, {
        header: `Progreso %`,
        accessorKey: 'progress',
        maxSize: 180,
    }]


interface DrillHoleTableComponentProps {
    collars: IDrillHoleCollarModel[]
}

export const DrillHoleTableComponent: FC<DrillHoleTableComponentProps> = (props) => {
    const [rowData, setRowData] = useState<IDrillHoleTableRow[]>([])


    useEffect(() => {
        const rowData = props.collars.map(value => {
            return {
                dhId: value.dhId,
                startDate: value.startDate,
                endDate: value.endDate,
                progress: value.compliancePercentage
            } as IDrillHoleTableRow
        })

        setRowData(rowData)
    }, [])


    return <Grid container spacing={spacing}>
        <Grid item xs={12}>
            <MaterialReactTable
                renderTopToolbarCustomActions={() => (
                    <Box m={margin}>
                        <Typography fontWeight='bold'>Sondajes</Typography>
                    </Box>
                )}

                columns={columns}
                data={rowData}
                enableColumnOrdering
                enableDensityToggle
                localization={MRT_Localization_ES}
            />
        </Grid>
    </Grid>
}