import {FC, useEffect, useRef, useState} from "react";
import {QrAnalysisEngine} from "../../../logic/qrAnalysisEngine";
import {useAnimationFrame} from "../../particleComponent";
import {QrCodeDrawing} from "../../../logic/qrCodeDrawing";
import {ImagePlantQrLocation} from "../../../logic/imagePlantQrLocation";
import {QRCode} from "jsqr";


export interface QrCameraDataTemplateComponentProps {
    onFoundedQr: (inputLocation: ImagePlantQrLocation, inputQrCode: QRCode) => void
    onValidateQr: (inputLocation: ImagePlantQrLocation, inputQrCode: QRCode) => Promise<boolean>
}

export const QrCameraDataTemplateComponent: FC<QrCameraDataTemplateComponentProps> = (props) => {
    const videoRef = useRef<HTMLVideoElement>(null)
    const qrLinesRef = useRef<HTMLCanvasElement>(null)
    const qrAnalysisRef = useRef<HTMLCanvasElement>(null)
    const currentTimeRef = useRef(0)

    const [plantQrDrawing] = useState<QrCodeDrawing>(new QrCodeDrawing(0, 0))


    useAnimationFrame(deltaTime => {
        currentTimeRef.current = currentTimeRef.current + deltaTime
        if (!qrLinesRef.current)
            return
        let canvas = qrLinesRef.current
        let context = canvas.getContext('2d')!
        context.clearRect(0, 0, canvas.width, canvas.height)

        plantQrDrawing.update(currentTimeRef.current)
    })

    const [qrTicking] = useState(true)
    const [count, setCount] = useState(0)

    const [qrAnalysisEngine] = useState(new QrAnalysisEngine())


    // Timer for qr analysis
    useEffect(() => {
        const timer = setTimeout(async () => {
            qrTicking && setCount(count + 1)
            await onQrSearchCallback()
        }, 50)
        return () => clearTimeout(timer)
        // eslint-disable-next-line
    }, [count, qrTicking])


    useEffect(() => {
        onWindowResizeCallback()
        plantQrDrawing.setColor(255, 204, 0)
        plantQrDrawing.setLineWidth(6)
        window.addEventListener('resize', onWindowResizeCallback);
    }, [])

    useEffect(() => {
        if (!qrLinesRef.current)
            return
        plantQrDrawing.setContext(qrLinesRef.current.getContext('2d')!)
    }, [qrLinesRef])


    useEffect(() => {

        let constraint = {
            video: {
                width: {ideal: 1920},
                height: {ideal: 1080},
                facingMode: 'environment',
            },
            audio: false
        }
        navigator.mediaDevices.getUserMedia(constraint)
            .then(stream => {
                let videoOutput = videoRef.current!
                videoOutput.srcObject = stream
                videoOutput.play()
            }).catch(reason => console.error(reason));
    }, [videoRef])


    const onWindowResizeCallback = () => {

        if (!videoRef.current || !qrLinesRef.current)
            return
        let videoOutput = videoRef.current
        let qrLines = qrLinesRef.current

        videoOutput.height = window.innerHeight - videoOutput.offsetTop;
        videoOutput.width = window.innerWidth - videoOutput.offsetLeft;

        qrLines.height = videoOutput.height;
        qrLines.width = videoOutput.width

        let qrSize = qrLines.width
        if (qrLines.height < qrLines.width)
            qrSize = qrLines.height

        qrSize *= 1 / 3

        plantQrDrawing.setCenter(qrLines.width / 2, qrLines.height / 2)
        plantQrDrawing.setHeight(qrSize)
        plantQrDrawing.setWidth(qrSize)
    }

    const onQrSearchCallback = async () => {
        if (!videoRef.current || !qrAnalysisRef.current || !qrLinesRef.current || videoRef.current.videoWidth === 0)
            return

        let video = videoRef.current
        let qrAnalysis = qrAnalysisRef.current
        let qrLines = qrLinesRef.current

        const width = video.videoWidth;
        const height = video.videoHeight;

        const factor = 1
        qrAnalysis.hidden = true
        qrAnalysis.width = width * factor
        qrAnalysis.height = height * factor

        let context = qrAnalysis.getContext('2d')!;
        if (!context) return
        context.drawImage(video, 0, 0, qrAnalysis.width, qrAnalysis.height)
        let imageData = context.getImageData(0, 0, qrAnalysis.width, qrAnalysis.height)
        // Process analysis
        let status = qrAnalysisEngine.searchQr(imageData, factor)
        if (qrAnalysisEngine.lastQrLocation)
            plantQrDrawing.updateBasedQrLocation(qrAnalysisEngine.lastQrLocation!,
                video.videoWidth, video.videoHeight)
        if (status) {
            let imagePlantQrLocation = qrAnalysisEngine.currentQrLocation!.toImageQrLocation(video.videoWidth,
                video.videoHeight, qrLines.width, qrLines.height)

            let currentQrCode = qrAnalysisEngine.currentQrCode!;
            let isValid = await props.onValidateQr(imagePlantQrLocation,currentQrCode)
            if (isValid) {
                props.onFoundedQr(imagePlantQrLocation,currentQrCode)
            }
        }
    }

    return <>
        <video ref={videoRef} className={"video-item"}/>
        <canvas ref={qrAnalysisRef} className={"qr-lines"}/>
        <canvas ref={qrLinesRef} className={"qr-lines"}/>
    </>
}