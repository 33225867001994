import {StopWatch} from "stopwatch-node";
import JSZip from "jszip";
import {BufferAttribute, BufferGeometry, Color, DoubleSide, Mesh, MeshStandardMaterial} from "three";

class MeshService {

    async getMesh(url: string) {

        const response = await fetch(url)
        const blob = await response.arrayBuffer()

        const zip = await JSZip.loadAsync(blob)
        const xUint8Array = await zip.file("x")!.async("arraybuffer")
        const yUint8Array = await zip.file("y")!.async("arraybuffer")
        const zUint8Array = await zip.file("z")!.async("arraybuffer")

        const dataViewX = new DataView(xUint8Array)
        const dataViewY = new DataView(yUint8Array)
        const dataViewZ = new DataView(zUint8Array)

        const vertices: number[] = []
        for (let offset = 0; offset < xUint8Array.byteLength; offset += 8) {
            const x = dataViewX.getFloat64(offset, true)
            const y = dataViewY.getFloat64(offset, true)
            const z = dataViewZ.getFloat64(offset, true)

            vertices.push(x)
            vertices.push(y)
            vertices.push(z)
        }

        const geometry = new BufferGeometry();
        geometry.setAttribute("position", new BufferAttribute(new Float32Array(vertices), 3))
        geometry.computeVertexNormals()
        geometry.computeBoundingBox()
        const material = new MeshStandardMaterial({
            side: DoubleSide,
            color: new Color("rgb(139,69,19)"),
        })
        return new Mesh(geometry, material)
    }
}

export const meshService = new MeshService()