import {spacing} from "../../../Resources/styles";
import {Button, Grid, Stack} from "@mui/material";
import {DrillHoleAssayViewerContainer} from "../../../models/demo/DrillHoleAssayViewerContainer";
import {drillHoleService} from "../../../api/demo/drillHoleService";
import {FC} from "react";
import {store} from "../../../redux/store";
import {setInProgressOffThunk, setInProgressOnThunk} from "../../../redux/progress";
import {useNavigate} from "react-router-dom";

interface DrillHoleViewerDownloadReportComponentProps {
    container: DrillHoleAssayViewerContainer
}

export const DrillHoleViewerDownloadReportComponent: FC<DrillHoleViewerDownloadReportComponentProps> = (props) => {
    const navigate = useNavigate()

    const downloadReport = async () => {
        await store.dispatch(setInProgressOnThunk())
        await drillHoleService.downloadReport()
        await store.dispatch(setInProgressOffThunk())
    }

    return <Grid container direction='row' spacing={spacing} alignItems='center'>

        <Grid item xs>
            <Button fullWidth variant='outlined' onClick={downloadReport}>
                Descargar Reporte
            </Button>
        </Grid>

        <Grid item xs='auto'>
            <Button fullWidth variant='outlined' onClick={() => {
                navigate("/demo/drills/table")
            }}>
                Tabla
            </Button>
        </Grid>


        <Grid item xs='auto'>
            <Button fullWidth variant='outlined' onClick={() => {
                navigate("/demo/drills/create")
            }}>
                Crear Campaña
            </Button>
        </Grid>
    </Grid>
}