import {apiUrl, apiUtils} from "./ApiConfig";
import {IPlantModel} from "./IPlantModel";
import {IPlantImageModel} from "./IPlantImageModel";

export interface IReadPlantsFilter {
    ids?: string[],
    statuses?: string[]
    shelfCubicleIds?: string[]
}

export interface IPlantUpdateInput {
    id: string,
    changeDescription?: boolean,
    description?: string,
    status?: string,
    placeInCubicleId?: string,
    placeOutCubicleId?: string,
    setThumbnailPlantImageId?:string
}

class PlantService {

    async readAsync(filter?: IReadPlantsFilter) {
        const requestOptions = apiUtils.getBaseRequest('GET')
        let uri = `${apiUrl}plant?`

        if (filter) {

            if (filter.ids && filter.ids.length > 0) {
                for (let id of filter.ids) {
                    uri += `Ids=${id}&`
                }
            }
            if (filter.statuses && filter.statuses.length > 0) {
                for (let status of filter.statuses) {
                    uri += `statuses=${status}&`
                }
            }

            if (filter.shelfCubicleIds && filter.shelfCubicleIds.length > 0) {
                for (let shelfCubicleId of filter.shelfCubicleIds) {
                    uri += `shelfCubiclesIds=${shelfCubicleId}&`
                }
            }
        }


        let response = await fetch(uri, requestOptions);
        return await response.json() as IPlantModel[];
    }

    async readOneAsync(id: string) {
        const requestOptions = apiUtils.getBaseRequest('GET')
        let uri = `${apiUrl}plant/${id}`

        let response = await fetch(uri, requestOptions);
        return await response.json() as IPlantModel;
    }

    async updateAsync(updateInput: IPlantUpdateInput) {
        const requestOptions = apiUtils.getBaseRequest('PUT')
        requestOptions.body = JSON.stringify(updateInput)
        let uri = `${apiUrl}plant`

        let response = await fetch(uri, requestOptions);
        return await response.json() as IPlantModel;
    }
}


export const plantService = new PlantService()

