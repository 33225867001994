import {Checkbox, FormControlLabel, Slider, Stack, Switch, Typography} from "@mui/material";
import {DrillHoleAssayViewerContainer} from "../../../models/demo/DrillHoleAssayViewerContainer";
import React, {FC, useEffect, useState} from "react";
import {spacing} from "../../../Resources/styles";
import {DrillHoleFilterAbstract} from "../../../models/demo/filter/DrillHoleFilterAbstract";
import {DrillHoleFilterByCut} from "../../../models/demo/filter/DrillHoleFilterByCut";
import {DrillHoleFilterAllVisible} from "../../../models/demo/filter/DrillHoleFilterAllVisible";

interface DrillHoleViewerFilterByCutComponentProps {
    container: DrillHoleAssayViewerContainer
    applyFilter?: (filter: DrillHoleFilterAbstract) => void
}

export const DrillHoleViewerFilterByCutComponent: FC<DrillHoleViewerFilterByCutComponentProps> = (props) => {

    const [isAvailable, setIsAvailable] = useState<boolean>(false)

    const [minCopper, setMinCopper] = useState<number>(0)
    const [maxCopper, setMaxCopper] = useState<number>(1)

    const [currentCopperRange, setCurrentCopperRange] =
        useState<number[]>([0, 1])
    const [copperRange, setCopperRange] = useState<number[]>([0, 1])
    const [isCutNoneAvailable, setIsCutNoneAvailable] = useState<boolean>(true)


    useEffect(() => {
        if (!isAvailable) {
            if (props.applyFilter)
                props.applyFilter(new DrillHoleFilterAllVisible())
            return
        }
        const minCut = copperRange[0]
        const maxCut = copperRange[1]
        if (props.applyFilter)
            props.applyFilter(new DrillHoleFilterByCut(minCut, maxCut, isCutNoneAvailable))

    }, [copperRange, isCutNoneAvailable, isAvailable])


    useEffect(() => {
        const range = props.container.getRange()
        setMinCopper(range.minCut)
        setMaxCopper(range.maxCut)

        setCurrentCopperRange([range.minCut, range.maxCut])
        setCopperRange([range.minCut, range.maxCut])
    }, [])


    return <Stack direction='row' spacing={spacing} alignItems='center'>
        <Checkbox checked={isAvailable} onChange={event => {
            setIsAvailable(event.target.checked);
        }}/>

        <Typography variant='body1'>
            Leyes de Cobre
        </Typography>
        <Slider disabled={!isAvailable} size="small" min={minCopper} max={maxCopper} defaultValue={currentCopperRange}
                value={currentCopperRange} sx={{width: 300}}

                onChange={(event, value) => {
                    setCurrentCopperRange(value as number[])
                }}
                onChangeCommitted={(event, value) => {
                    setCopperRange(value as number[])
                }}
                valueLabelDisplay="auto" step={0.1}
                marks={[{value: minCopper, label: `${minCopper}%`}, {value: maxCopper, label: `${maxCopper}%`}]}
        />

        <FormControlLabel disabled={!isAvailable} control={<Switch checked={isCutNoneAvailable} onChange={event => {
            setIsCutNoneAvailable(event.target.checked);
        }}/>} label="Incluir -99"/>

    </Stack>
}


