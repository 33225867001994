import {IDrillHoleCollarClientModel} from "../IDrillHoleCollarClientModel";
import {DrillHoleFilterAbstract} from "./DrillHoleFilterAbstract";

export class DrillHoleFilterByCut extends DrillHoleFilterAbstract {
    private readonly _minGrade?: number;
    private readonly _maxGrade?: number;
    private readonly _includeNone: boolean;
    private readonly _noneValue: number = -99;

    constructor(minGrade?: number, maxGrade?: number, includeNone: boolean = false) {
        super();
        this._minGrade = minGrade;
        this._maxGrade = maxGrade;
        this._includeNone = includeNone;
    }

    filter(collar: IDrillHoleCollarClientModel): boolean[] {

        const cuts = collar.assay.map(value => value.cut)

        const status: boolean[] = []

        for (const cut of cuts) {
            if (this._includeNone && cut == this._noneValue) {
                status.push(true)
            } else if (this._maxGrade && this._minGrade) {
                if (cut >= this._minGrade && cut <= this._maxGrade)
                    status.push(true)
                else status.push(false)
            } else if (this._maxGrade) {
                if (cut <= this._maxGrade)
                    status.push(true)
                else status.push(false)
            } else if (this._minGrade) {
                if (cut >= this._minGrade!)
                    status.push(true)
                else status.push(false)
            } else {
                status.push(false)
            }
        }

        if (this._maxGrade && this._minGrade) {
            for (const cut of cuts)
                if (cut >= this._minGrade && cut <= this._maxGrade)
                    status.push(true)
                else status.push(false)
        } else if (this._maxGrade) {
            for (const cut of cuts)
                if (cut <= this._maxGrade)
                    status.push(true)
                else status.push(false)
        } else {
            for (const cut of cuts)
                if (cut >= this._minGrade!)
                    status.push(true)
                else status.push(false)
        }
        return status;
    }
}



