import {Button, Typography} from "@mui/material";
import {margin, padding} from "../../../Resources/styles";
import Box from "@mui/material/Box";
import {useNavigate} from "react-router-dom";

export const AnonymousEpipremnumDetailsComponent = () => {
    const navigate = useNavigate()

    return <Box m={margin} p={padding}><Typography variant='h6'>
        Página en construcción
    </Typography>
        <Typography variant='body1'>
            Conéctate a partir de mayo y verás novedades interesantes de tu planta.
        </Typography>

        <Button href='https://epipremnum.cl' target='_blank' rel='noreferrer' variant='outlined'>
            Tienda Epipremnum
        </Button>
    </Box>
}