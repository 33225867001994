import {apiUrl, apiUtils} from "./ApiConfig";
import {IPlantModel} from "./IPlantModel";
import {IPlantSheetModel} from "./IPlantSheetModel";
import moment from "moment/moment";


export interface IPlantSheetFilter {
    ids?: string[],
    plantIds?: string[],
}

export interface IPlantSheetUpdate {
    id: string,
    setIsPrinted?: boolean,
}

class PlantSheetService {
    async readAsync(filter?: IPlantSheetFilter) {
        const requestOptions = apiUtils.getBaseRequest('GET')
        let uri = `${apiUrl}plantSheet?`

        if (filter) {

            if (filter.ids && filter.ids.length > 0) {
                for (let id of filter.ids) {
                    uri += `Ids=${id}&`
                }
            }
            if (filter.plantIds && filter.plantIds.length > 0) {
                for (let status of filter.plantIds) {
                    uri += `plantIds=${status}&`
                }
            }
        }
        let response = await fetch(uri, requestOptions);
        return await response.json() as IPlantSheetModel[];
    }

    async updateAsync(updateInput?: IPlantSheetUpdate) {
        const requestOptions = apiUtils.getBaseRequest('PUT')
        let uri = `${apiUrl}plantSheet`
        requestOptions.body = JSON.stringify(updateInput)

        let response = await fetch(uri, requestOptions);
        return await response.json() as IPlantSheetModel;
    }

    async downloadSheet(plantSheetId: string) {
        const requestOptions = apiUtils.getBaseRequest('GET')
        let uri = `${apiUrl}Developer/get_qr_image/${plantSheetId}`
        let response = await fetch(`${uri}`, requestOptions);
        let blob = await response.blob()


        const url = window.URL.createObjectURL(
            new Blob([blob]),
        );
        const link = document.createElement('a');

        link.href = url;
        link.setAttribute(
            'download',
            `${plantSheetId}.png`,
        );

        // Append to html link element page
        document.body.appendChild(link);
        // Start download
        link.click();
        // Clean up and remove the link
        link.parentNode!.removeChild(link);
    }
}

export const plantSheetService = new PlantSheetService()
