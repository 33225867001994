import {useAuth0} from "@auth0/auth0-react";
import {
    AnonymousEpipremnumDetailsComponent
} from "../../anonymous/anonymousEpipremnum/anonymousEpipremnumDetailsComponent";
import {EpipremnumDetailsComponent} from "../../epipremnum/epipremnumDetails/epipremnumDetailsComponent";
import React from "react";

export const EpipremnumDetailsRoute = () => {
    const {isAuthenticated, loginWithRedirect, user} = useAuth0()

    if (!isAuthenticated)
        return <>
                <AnonymousEpipremnumDetailsComponent/>
            </>
    return <>
            <EpipremnumDetailsComponent/>
        </>
}