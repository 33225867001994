import {useEffect, useState} from "react";
import moment, {Moment} from "moment/moment";
import {useSelector} from "react-redux";
import {RootState, store} from "../../../redux/store";
import {
    deselectEpipremnumThunk,
    setSelectEpipremnumThunk
} from "../../../redux/epipremnum";
import {Strings} from "../../../Resources/Strings";
import {Autocomplete, Grid, TextField, Typography} from "@mui/material";
import {margin, spacing} from "../../../Resources/styles";
import Box from "@mui/material/Box";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import LoadingButton from "@mui/lab/LoadingButton";
import EditIcon from "@mui/icons-material/Edit";
import {useNavigate, useParams} from "react-router-dom";
import {epipremnumService} from "../../../api/epipremnumService";
import {EpipremnumStatusConstants} from "../../../api/epipremnumStatus";

export const EpipremnumEditComponent = () => {

    const {id} = useParams()
    const navigate = useNavigate()
    const [plantDate, setPlantDate] = useState<Moment>(moment());
    const [name, setName] = useState<string>("");
    const [description, setDescription] = useState<string>("");
    const [status, setStatus] = useState<string>("");

    const [isUpdating, setIsUpdating] = useState<boolean>()

    const {currentEpipremnumSelected} = useSelector((state: RootState) => state.epipremnum)

    useEffect(() => {
        const loadData = async () => {
            await store.dispatch(deselectEpipremnumThunk())
            await store.dispatch(setSelectEpipremnumThunk(id!))
        }
        loadData()
    }, [])

    // Load initial values
    useEffect(() => {
        // Reset values
        if (!currentEpipremnumSelected) {
            setName("")
            setDescription("")
            setPlantDate(moment())
            setStatus(EpipremnumStatusConstants.Unavailable)
            return
        }

        // Set values
        setDescription(currentEpipremnumSelected.description)
        if (!currentEpipremnumSelected.name)
            setName("")
        else
            setName(currentEpipremnumSelected.name)
        setStatus(currentEpipremnumSelected.status)
        setPlantDate(moment(currentEpipremnumSelected.plantDate))
    }, [currentEpipremnumSelected])

    const onDescriptionChanged = (event: any | HTMLInputElement) => {
        setDescription(event.target.value)
    }

    const onNameChanged = (event: any | HTMLInputElement) => {
        setName(event.target.value)
    }

    const editEpipremnumCallback = async () => {
        setIsUpdating(true)
        if (description !== currentEpipremnumSelected?.description)
            await epipremnumService.setDescription(id!, description)
        if (plantDate.toISOString() !== currentEpipremnumSelected?.plantDate.toString())
            await epipremnumService.setPlantDate(id!, plantDate)
        if (name && name !== currentEpipremnumSelected?.name)
            await epipremnumService.setName(id!, name)
        if (status && status !== currentEpipremnumSelected?.status)
            await epipremnumService.setStatus(id!, status!)
        setIsUpdating(false)
        navigate(-1)
    }

    if (!currentEpipremnumSelected)
        return <></>

    return <Box m={margin}>
        <Grid spacing={spacing} container direction="row"
              justifyContent="space-evenly"
              alignItems="center">
            <Grid item xs={12}>
                <Typography variant='h6'
                            fontWeight='bold'>{Strings.EditEpipremnum} {currentEpipremnumSelected.id}</Typography>
            </Grid>

            <Grid item xs={12}>
                <TextField
                    fullWidth
                    label={Strings.Name}
                    value={name} onChange={onNameChanged}
                    variant="outlined"/>
            </Grid>
            <Grid item xs={12}>
                <TextField
                    fullWidth
                    label={Strings.Description}
                    value={description} onChange={onDescriptionChanged}
                    multiline
                    minRows={4}
                    maxRows={10}
                    variant="outlined"
                />
            </Grid>


            <Grid item xs='auto'>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                        label={Strings.PlantDate}
                        value={plantDate}
                        onChange={(newValue) => {
                            if (newValue)
                                setPlantDate(newValue);
                        }}
                        renderInput={(params) => <TextField {...params} />}
                    />
                </LocalizationProvider>
            </Grid>

            <Grid item xs='auto'>
                <Autocomplete
                    value={status}
                    onChange={(event, value) => {
                        setStatus(value)
                    }}
                    disablePortal
                    disableClearable
                    options={EpipremnumStatusConstants.getOptions()}
                    sx={{width: 300}}
                    renderInput={(params) => <TextField {...params} label={Strings.Status}/>}
                />
            </Grid>

            <Grid item xs>
                <LoadingButton loading={isUpdating} loadingPosition="start"
                               startIcon={<EditIcon/>}
                               onClick={editEpipremnumCallback} fullWidth
                               variant="contained">{Strings.Edit}</LoadingButton>
            </Grid>
        </Grid>
    </Box>
}