import {useEffect, useState} from "react";
import {drillHoleService} from "../../../api/demo/drillHoleService";
import {IDrillHoleCollarModel} from "../../../models/demo/IDrillHoleCollarModel";
import {DrillHoleTableComponent} from "./DrillHoleTableComponent";
import {store} from "../../../redux/store";
import {setInProgressOffThunk, setInProgressOnThunk} from "../../../redux/progress";

export const DrillHoleAllComponent = () => {

    const [collars, setCollars] = useState<IDrillHoleCollarModel[]>()

    useEffect(() => {
        const loadData = async () => {
            await store.dispatch(setInProgressOnThunk())
            const models = await drillHoleService.read()
            await store.dispatch(setInProgressOffThunk())
            setCollars(models)
        }
        loadData()
    }, [])

    return <>
        {collars && <DrillHoleTableComponent collars={collars}/>}
    </>
}

