import {useNavigate, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {imageStyle, spacing} from "../../Resources/styles";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
    Accordion, AccordionDetails, AccordionSummary,
    Autocomplete, Avatar, Box, Button, ButtonGroup, Fab,
    Grid,
    IconButton,
    ImageList,
    ImageListItem,
    ImageListItemBar, ListItemAvatar,
    TextField, Tooltip,
    Typography
} from "@mui/material";
import {IPlantModel} from "../../api/IPlantModel";
import {IPlantUpdateInput, plantService} from "../../api/plantService";
import {PlantStatusConstants} from "../../api/plantStatusConstants";
import {Strings} from "../../Resources/Strings";
import {plantImageService} from "../../api/planImageService";
import * as muiImage from "mui-image";
import {IPlantImageModel} from "../../api/IPlantImageModel";
import DeleteIcon from "@mui/icons-material/Delete";
import moment from "moment";
import {useConfirm} from "material-ui-confirm";
import {store} from "../../redux/store";
import {setInProgressOffThunk, setInProgressOnThunk} from "../../redux/progress";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ViewInArIcon from '@mui/icons-material/ViewInAr';
import {AccountBox, ArrowForward} from "@mui/icons-material";
import Inventory2Icon from '@mui/icons-material/Inventory2';

export const PlantEditComponent = () => {
    const {id} = useParams()

    const options = PlantStatusConstants.getOptions()
    const [status, setStatus] = useState<string | undefined>(options[0])
    const [statusIsDirty, setStatusIsDirty] = useState<boolean>(false)

    const [description, setDescription] = useState<string>()
    const [descriptionIsDirty, setDescriptionIsDirty] = useState<boolean>(false)

    const [propertiesExpanded, setPropertiesExpanded] = useState<boolean>(true)

    const confirm = useConfirm()
    const navigate = useNavigate()

    const [plant, setPlant] = useState<IPlantModel>()
    const [plantImages, setPlantImages] =
        useState<IPlantImageModel[]>()


    const loadPlant = async () => {
        let plant = await plantService.readOneAsync(id!)
        setPlant(plant)
        const plantImages = await plantImageService.readAsync({
            plantIds: [plant.id]
        })
        setPlantImages(plantImages)
    }

    const onChangeStatusCallback = (event: any, newStatus: string | undefined) => {
        setStatus(newStatus)
        setStatusIsDirty(true)
    }

    const onChangeDescriptionChangeCallback = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDescription(event.target.value)
        setDescriptionIsDirty(true)
    }

    // -- Main Function for Updating the Properties --
    const onUpdatePropertiesCallback = async () => {

        try {
            await store.dispatch(setInProgressOnThunk())
            const updateInput: IPlantUpdateInput = {
                id: id!
            }
            if (descriptionIsDirty) {
                updateInput.changeDescription = true
                updateInput.description = description
            }
            if (statusIsDirty) {
                updateInput.status = status
            }

            await plantService.updateAsync(updateInput)

            setDescriptionIsDirty(false)
            setStatusIsDirty(false)

        } finally {
            await store.dispatch(setInProgressOffThunk())
        }
    }

    useEffect(() => {
        if (!plant) return
        setDescription(plant.description)
        setStatus(plant.status)
    }, [plant])

    useEffect(() => {
        loadPlant()
    }, [id])

    const deleteImageCallback = async (plantImage: IPlantImageModel) => {
        await confirm().then(async value => {
            await store.dispatch(setInProgressOnThunk())
            await plantImageService.deleteAsync(plantImage.id)
            await loadPlant()
        }).catch(() => {

        }).finally(async () => {
            await store.dispatch(setInProgressOffThunk())
        })
    }

    const setImageThumbnail = async (plantImage: IPlantImageModel) => {
        await confirm().then(async value => {
            await store.dispatch(setInProgressOnThunk())

            await plantService.updateAsync({
                id: id!,
                setThumbnailPlantImageId: plantImage.id
            })
            // await plantImageService.upda(plantImage.id)
            await loadPlant()
        }).catch(() => {

        }).finally(async () => {
            await store.dispatch(setInProgressOffThunk())
        })
    }

    const removeFromCubicleCallback = async (cubicleId: string) => {
        await confirm().then(async value => {
            await store.dispatch(setInProgressOnThunk())
            await plantService.updateAsync({
                id: id!,
                placeOutCubicleId: cubicleId
            })
            await loadPlant()
        }).catch(() => {

        }).finally(async () => {
            await store.dispatch(setInProgressOffThunk())
        })
    }

    return <>
        {plant && <Grid container spacing={spacing}>
            <Grid item xs={12}>
                <Typography variant='h5'>
                    {Strings.Edit} {Strings.Plant} {plant.name}
                </Typography>
            </Grid>

            <Grid item xs={12}>
                <Accordion expanded={propertiesExpanded}
                           onChange={(event, expanded) => setPropertiesExpanded(expanded)}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                        <Typography variant='h6'>{Strings.Properties}</Typography>
                    </AccordionSummary>

                    <AccordionDetails>
                        <Grid container spacing={spacing}>
                            <Grid item xs='auto'>
                                <muiImage.Image width='300px'
                                                {...imageStyle}
                                                src={plant.thumbnailPictureUrl}/>

                            </Grid>

                            <Grid item xs>
                                <Grid container spacing={spacing}>
                                    <Grid item xs={12}>
                                        <Autocomplete disableClearable value={status}
                                                      onChange={onChangeStatusCallback}
                                                      renderInput={(params) =>
                                                          <TextField {...params} label={Strings.Status}/>}
                                                      options={options}/>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            label={Strings.Description}
                                            value={description} onChange={onChangeDescriptionChangeCallback}
                                            multiline
                                            minRows={4}
                                            maxRows={10}
                                            variant="outlined"
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Button fullWidth disabled={!descriptionIsDirty && !statusIsDirty}
                                                onClick={onUpdatePropertiesCallback} variant='contained'>
                                            {Strings.Update}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion>

                {/* -- Cubicles -- */}
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                        <Grid container spacing={spacing}>
                            <Grid item xs='auto'>
                                <Typography variant='h6'>{Strings.Cubicle}</Typography>
                            </Grid>
                        </Grid>
                    </AccordionSummary>
                    <AccordionDetails>
                        <List>
                            <ListItem key='cubicle-header'
                                      secondaryAction={
                                          <Box>
                                              <IconButton onClick={() =>
                                                  navigate(`/plant/selectCubicle/${id!}`)}>
                                                  <Inventory2Icon color='primary'/>
                                              </IconButton>
                                          </Box>}>

                                <ListItemText primary={Strings.SetCubicles}/>
                            </ListItem>

                            {plant.storedInCubicles.map(storedCubicle =>
                                <ListItem key={storedCubicle._id}
                                          secondaryAction={
                                              <Box>
                                                  <IconButton onClick={() =>
                                                      removeFromCubicleCallback(storedCubicle._id)}>
                                                      <DeleteIcon
                                                          color='error'/>
                                                  </IconButton>
                                              </Box>}>
                                    <ListItemAvatar>
                                        <Avatar variant='circular'>
                                            <ViewInArIcon/>
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary={`${storedCubicle._id}`}/>
                                </ListItem>)}
                        </List>
                    </AccordionDetails>
                </Accordion>

                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                        <Typography variant='h6'>{Strings.Pictures}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {plantImages && <ImageList>
                            {plantImages.map(plantImage =>
                                <ImageListItem key={plantImage.id}>
                                    <muiImage.Image width='300px' alt={plantImage.id}
                                                    {...imageStyle}
                                                    src={plantImage.url}/>
                                    <ImageListItemBar title={moment(plantImage.pictureDate).toDate().toDateString()}
                                                      subtitle={plantImage.id}
                                                      actionPosition='left'
                                                      actionIcon={
                                                          <Grid container>
                                                              <Grid item xs='auto'>
                                                                  <Tooltip title={Strings.UpdatedThumbnail}>
                                                                      <IconButton color='secondary'
                                                                                  onClick={() => setImageThumbnail(plantImage)}
                                                                                  aria-label={`star ${plantImage.id}`}>
                                                                          <AccountBox/>
                                                                      </IconButton>
                                                                  </Tooltip>
                                                              </Grid>

                                                              <Grid item xs='auto'>
                                                                  <Tooltip title={Strings.Delete}>
                                                                      <IconButton color='error'
                                                                                  onClick={() => deleteImageCallback(plantImage)}
                                                                                  aria-label={`star ${plantImage.id}`}>
                                                                          <DeleteIcon/>
                                                                      </IconButton>
                                                                  </Tooltip>
                                                              </Grid>
                                                          </Grid>
                                                      }
                                                      position='bottom'>
                                    </ImageListItemBar>
                                </ImageListItem>
                            )}
                        </ImageList>}

                    </AccordionDetails>
                </Accordion>
            </Grid>
        </Grid>}
    </>
}
