export class EpipremnumStatusConstants {
    public static Unavailable: string = "unavailable";
    public static AvailableNotSalable: string = "available_not_salable";
    public static AvailableSalable: string = "available_salable";
    public static SoldMercadoLibre: string = "sold_mercado_libre";
    public static SoldBlackMarket: string = "sold_black_market";
    public static SoldWebsite: string = "sold_website";
    public static Destroyed: string = "destroyed";
    public static Given: string = "given";

    public static getOptions(): string[] {
        return [EpipremnumStatusConstants.Unavailable,
            EpipremnumStatusConstants.AvailableNotSalable,
            EpipremnumStatusConstants.AvailableSalable,
            EpipremnumStatusConstants.SoldMercadoLibre,
            EpipremnumStatusConstants.SoldBlackMarket,
            EpipremnumStatusConstants.SoldWebsite,
            EpipremnumStatusConstants.Destroyed,
            EpipremnumStatusConstants.Given,
        ]
    }
}


