import {useEffect, useState} from "react";
import {Alert, Button, Grid, TextField, Typography} from "@mui/material";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import moment, {Moment} from "moment";
import {Strings} from "../../../Resources/Strings";
import {margin, spacing} from "../../../Resources/styles";
import Box from "@mui/material/Box";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import LoadingButton from "@mui/lab/LoadingButton";
import {RootState, store} from "../../../redux/store";
import {
    setEpipremnumAssignedThunk,
    setEpipremnumAssignedStatusThunk
} from "../../../redux/epipremnum";
import {SnackbarKey, useSnackbar} from 'notistack'
import {useSelector} from "react-redux";

export const EpipremnumAssignComponent = () => {
    const [plantDate, setPlantDate] = useState<Moment>(moment());
    const [description, setDescription] = useState<string>("");
    const [name, setName] = useState<string>("");
    const [price, setPrice] = useState<number>(19990);
    const {enqueueSnackbar, closeSnackbar} = useSnackbar()
    const [isCreating, setIsCreating] = useState<boolean>()

    const {currentEpipremnumSelected} = useSelector((state: RootState) => state.epipremnum)

    useEffect(() => {
        if (currentEpipremnumSelected) {
            setName(currentEpipremnumSelected.name)
        }
    }, [currentEpipremnumSelected])

    const setAssignedCallback = async () => {
        setIsCreating(true)
        let id = currentEpipremnumSelected!.id
        await store.dispatch(setEpipremnumAssignedThunk(id, plantDate, description, name, price))
        setIsCreating(false)

        enqueueSnackbar(`${Strings.AssignedEpipremnum}`, {
            content: (key, message) => {
                return <div key={key}>
                    <Alert severity='success' action={<Grid container spacing={spacing}>
                        <Grid item>
                            <Button color='warning' size='small' variant='outlined'
                                    onClick={async () => {
                                        await undoEpipremnumAssignCallback(key, id)
                                    }}>
                                {Strings.Undo}
                            </Button>
                        </Grid>
                    </Grid>}>{message}</Alert>
                </div>
            }
        })
    }

    const onDescriptionChanged = (event: any | HTMLInputElement) => {
        if (!event.target.value)
            setDescription("")
        else setDescription(event.target.value)
    }

    const onPriceChanged = (event: any | HTMLInputElement) => {
        setPrice(event.target.value)
    }


    const onNameChanged = (event: any | HTMLInputElement) => {
        if (!event.target.value)
            setName("")
        else setName(event.target.value)
    }

    const undoEpipremnumAssignCallback = async (key: SnackbarKey, id: string) => {
        try {
            closeSnackbar(key)
            await store.dispatch(setEpipremnumAssignedStatusThunk(id, false))
            enqueueSnackbar(`${Strings.UnassignedEpipremnum}`, {
                content: (key, message) => {
                    return <div key={key}>
                        <Alert severity='warning' onClose={() => closeSnackbar(key)}>{message}</Alert>
                    </div>
                }
            })
        } catch (e) {
            console.error(e)
        }
    }

    if (!currentEpipremnumSelected)
        return <></>

    return <Box m={margin}>
        <Grid spacing={spacing} container direction="row"
              justifyContent="space-evenly"
              alignItems="center">
            <Grid item xs={12}>
                <Typography variant='h6'
                            fontWeight='bold'>{Strings.AssignEpipremnum} {currentEpipremnumSelected.id}</Typography>
            </Grid>

            <Grid item xs={12}>
                <TextField
                    fullWidth
                    label={Strings.Name}
                    value={name} onChange={onNameChanged}
                    variant="outlined"/>
            </Grid>

            <Grid item xs={12}>
                <TextField
                    fullWidth
                    label={Strings.Description}
                    value={description} onChange={onDescriptionChanged}
                    multiline
                    minRows={4}
                    maxRows={10}
                    variant="outlined"
                />
            </Grid>

            <Grid item xs={12}>
                <TextField
                    fullWidth
                    label={Strings.Price}
                    inputProps={{type: 'number'}}
                    value={price} onChange={onPriceChanged}
                    variant="outlined"
                />
            </Grid>

            <Grid item xs='auto'>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                        label={Strings.PlantDate}
                        value={plantDate}
                        onChange={(newValue) => {
                            if (newValue)
                                setPlantDate(newValue);
                        }}
                        renderInput={(params) => <TextField {...params} />}
                    />
                </LocalizationProvider>
            </Grid>


            <Grid item xs>
                <LoadingButton loading={isCreating} loadingPosition="start"
                               startIcon={<AddCircleIcon/>}
                               onClick={setAssignedCallback} fullWidth
                               variant="contained">{Strings.Assign}</LoadingButton>
            </Grid>
        </Grid>
    </Box>
}





