import {apiUrl, apiUtils} from "../ApiConfig";
import {IPhaseModel} from "../../models/demo/IPhaseModel";
import {readShapeTriangles} from "../../logic/Viewer3D/threeUtils";

class PhaseService {

    async getItems() {
        let requestInit = apiUtils.getBaseRequest("GET")
        let url = `${apiUrl}phase`
        let response = await fetch(url, requestInit)
        return (await response.json()) as IPhaseModel[]
    }

    async readContent(phaseModel: IPhaseModel) {

        let url = phaseModel.trianglesUrl
        let trianglesResponse = await fetch(url, {
            method: 'GET',
            headers: {}
        })

        return readShapeTriangles(await trianglesResponse.text(), false  )
    }

}

export const phaseService = new PhaseService()


